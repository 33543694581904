import React from "react";
import { useSelector } from "react-redux";
import NoAuthModal from "../Modals/NoAuthModal";

const PrivateRoute = ({ element: Component, ...rest }) => {
  const isAuthenticated = useSelector((state) => state.auth.status);

  if (!isAuthenticated) {
    return <NoAuthModal />;
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
