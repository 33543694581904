import React, { useState } from "react";
import { NOTIFICATION } from "../../../types/notification";
import { useSelector } from "react-redux";
import { error_message } from "../../../constants/Errors";

const EditNotificationCard = ({
  notificationData,
  setNotificationData,
  isEditing,
  viewOnly,
}) => {
  const [dateError, setDateError] = useState("");

  const isAdminLoggedIn: boolean = useSelector((state) => state.auth.isAdmin);

  isEditing = viewOnly === isAdminLoggedIn ? true : isEditing;
  viewOnly = viewOnly || !isAdminLoggedIn;

  const updateNotificationInformation = (
    key: keyof NOTIFICATION,
    value: string | boolean,
  ) => {
    setNotificationData((prevState) => {
      if (prevState) {
        return {
          ...prevState,
          [key]: value,
        };
      }
      return prevState;
    });

    if (key === "start_date" || key === "end_date") {
      validateDates(
        key === "start_date" ? value : notificationData?.start_date,
        key === "end_date" ? value : notificationData?.end_date,
      );
    }
  };

  const validateDates = (startDate, endDate) => {
    if (new Date(startDate) > new Date(endDate)) {
      setDateError(error_message.notification.start_data);
    } else {
      setDateError("");
    }
  };

  return (
    <div className="rounded-xl border border-text-field-border bg-white py-6 px-3.5">
      <div className="mt-2 flex items-end justify-between">
        <div className="w-full">
          <div>
            <h4 className={"button-small-status w-35 mb-10"}>
              {notificationData?.status === true ? "配信中" : "停止"}
            </h4>
          </div>

          {isEditing && !viewOnly ? (
            <>
              <div>
                <div className="text-font-label-blue font-bold text-xl mb-2">
                  タイトル <span className="text-red-500">*</span>
                </div>
                <input
                  type="text"
                  value={notificationData?.title}
                  onChange={(e) =>
                    updateNotificationInformation("title", e.target.value)
                  }
                  className="text-title-md font-bold text-black w-1/2 border border-text-field-border rounded-xl p-2"
                />
              </div>

              <div className="divider mt-5"></div>

              <div className="w-full mb-4">
                <div className="text-font-label-blue font-bold text-xl mb-2">
                  内容 <span className="text-red-500">*</span>
                </div>
                <textarea
                  value={notificationData?.content}
                  onChange={(e) =>
                    updateNotificationInformation("content", e.target.value)
                  }
                  className="text-title-md text-black w-3/4 min-h-150 border border-text-field-border rounded-xl p-2"
                />
              </div>

              <div className="divider mt-5"></div>

              <div className="text-font-label-blue font-bold text-xl mb-2">
                配信期間 <span className="text-red-500">*</span>
              </div>

              <div className="flex items-center">
                <div className="mr-2">
                  <div className="text-md font-medium">〜から</div>
                  <input
                    type="date"
                    value={notificationData?.start_date.split("T")[0]}
                    onChange={(e) =>
                      updateNotificationInformation(
                        "start_date",
                        e.target.value,
                      )
                    }
                    className="text-title-md font-bold text-black mb-2 w-auto border border-text-field-border rounded-xl p-2"
                  />
                </div>

                <div className="mx-2 text-2xl font-bold">〜</div>

                <div className="ml-2">
                  <div className="text-md font-medium">〜まで</div>
                  <input
                    type="date"
                    value={notificationData?.end_date.split("T")[0]}
                    onChange={(e) =>
                      updateNotificationInformation("end_date", e.target.value)
                    }
                    className="text-title-md font-bold text-black mb-2 w-auto border border-text-field-border rounded-xl p-2"
                  />
                </div>
              </div>

              {dateError && <p className="text-red-500">{dateError}</p>}
            </>
          ) : (
            <>
              <div>
                <h2 className="text-md font-medium">タイトル</h2>
                <p className="text-title-md text-black mb-5">
                  {notificationData?.title}
                </p>
              </div>

              <div>
                <h2 className="text-md font-medium">作成日</h2>
                <p className="text-title-md text-black mb-5">
                  {notificationData?.created_at.split("T")[0]}
                </p>
              </div>

              <div className="w-full mb-4">
                <h2 className="text-md font-medium">内容</h2>
                <p className="text-title-md text-black mb-5">
                  {notificationData?.content}
                </p>
              </div>

              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3 text-left text-xl font-medium text-gray300 uppercase tracking-wider"
                      >
                        配信期間
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    <tr>
                      <td className="px-6 py-4 whitespace-nowrap text-xl font-medium text-gray-900">
                        {notificationData?.start_date.split("T")[0]}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-xl font-large text-gray-900">
                        ~
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-xl text-gray-500">
                        {notificationData?.end_date.split("T")[0]}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditNotificationCard;
