import React, { useEffect, useState } from "react";
import { getCompanyContractData } from "../../services/API/Requests";
import LoadingIcon from "../../common/LoadingIcon";
import { CONTRACT } from "../../types/contract";
import { error_message } from "../../constants/Errors";
import { CONTRACT_STATUS } from "../../constants/Enums";

const DisplayStatus = ({ contract }: { contract: CONTRACT }) => {
  const displayStatus =
    contract.status === CONTRACT_STATUS.ACTIVE
      ? "契約中"
      : contract.status === CONTRACT_STATUS.INACTIVE
      ? "未契約"
      : contract.status === CONTRACT_STATUS.EXPIRED
      ? "契約終了"
      : "不明";

  const statusTextClass =
    contract.status === CONTRACT_STATUS.INACTIVE
      ? "text-red-500"
      : contract.status === CONTRACT_STATUS.EXPIRED
      ? "text-yellow-500"
      : "text-gray-900";

  const hasContract = contract.status === CONTRACT_STATUS.ACTIVE;

  const validateEndDate = (endDate) => {
    if (!endDate) {
      return false;
    } else {
      const year = new Date(endDate).getFullYear();
      return year < 2999;
    }
  };

  return (
    <tr key={contract.id}>
      <td className="py-4 whitespace-nowrap text-xl font-medium text-gray-900 w-1/5">
        {contract.app_name}
      </td>
      <td
        className={`py-4 whitespace-nowrap text-xl font-large ${statusTextClass} w-1/5`}
      >
        {displayStatus}
      </td>
      <td className={`py-4 whitespace-nowrap text-xl ${statusTextClass} w-1/5`}>
        {hasContract ? `${contract.contract_count || "0"}` : displayStatus}
      </td>
      <td
        className={`py-4 whitespace-nowrap text-xl font-large ${statusTextClass} w-1/5`}
      >
        {contract.start_date
          ? `${contract.start_date}~${
              validateEndDate(contract.end_date) ? contract.end_date : ""
            }`
          : "ー"}
      </td>
      <td
        className={`pl-5 py-4 whitespace-nowrap text-xl font-large ${statusTextClass} w-1/5`}
      >
        {contract.status === CONTRACT_STATUS.INACTIVE
          ? "ー"
          : contract.current_users || "0"}
      </td>
    </tr>
  );
};

const TenantContractTable = ({ id }: { id: string | null }) => {
  const [contractData, setContractData] = useState<CONTRACT[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const activeLicenses = contractData.filter(
    (contract) => contract.status === "active",
  ).length;

  useEffect(() => {
    const fetchContractData = async () => {
      setLoading(true);
      try {
        await getCompanyContractData(setContractData, setError, setLoading, id);
      } catch (error) {
        setError(error.message || "");
      } finally {
        setLoading(false);
      }
    };

    fetchContractData();
  }, [id]);

  const [isSorted, setIsSorted] = useState(false);

  useEffect(() => {
    if (!contractData || contractData.length === 0 || isSorted) return;

    const appsOrder = ["KAKELY", "YOBELY", "WorkJoy"];
    const sorted = [...contractData].sort((a, b) => {
      const indexA = appsOrder.indexOf(a.app_name);
      const indexB = appsOrder.indexOf(b.app_name);
      return (indexA === -1 ? 999 : indexA) - (indexB === -1 ? 999 : indexB);
    });

    setContractData(sorted);
    setIsSorted(true);
  }, [contractData, isSorted]);

  return (
    <div className="overflow-x-auto">
      {loading ? (
        <LoadingIcon />
      ) : error ? (
        <p className="text-red-500">Error: {error}</p>
      ) : activeLicenses >= 1 ? (
        <table className="min-w-full divide-y divide-mayo-divider-dark table-fixed">
          <thead>
            <tr>
              <th
                scope="col"
                className="pb-5 text-left text-xl font-medium text-font-label-blue uppercase tracking-wider w-1/5"
              >
                アプリ
              </th>
              <th
                scope="col"
                className="pb-5 text-left text-xl font-medium text-font-label-blue uppercase tracking-wider w-1/5"
              >
                契約状態
              </th>
              <th
                scope="col"
                className="pb-5 text-left text-xl font-medium text-font-label-blue uppercase tracking-wider w-1/5"
              >
                ライセンス数
              </th>
              <th
                scope="col"
                className="pb-5 text-left text-xl font-medium text-font-label-blue uppercase tracking-wider w-1/5"
              >
                契約期間
              </th>
              <th
                scope="col"
                className="pl-5 pb-5 text-left text-xl font-medium text-font-label-blue tracking-wider w-1/5"
              >
                利用ユーザー数
              </th>
            </tr>
          </thead>
          <tbody>
            {contractData
              .filter(
                (contract) =>
                  contract.status !== CONTRACT_STATUS.EXPIRED &&
                  contract.status !== CONTRACT_STATUS.INACTIVE,
              )
              .map((contract) => (
                <DisplayStatus contract={contract} key={contract.id} />
              ))}
          </tbody>
        </table>
      ) : (
        <h1 className="flex text-center text-md">
          {error_message.tenant.no_active_contract}
        </h1>
      )}
    </div>
  );
};

export default TenantContractTable;
