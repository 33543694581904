import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ContactsTable from "../../components/Tables/ContactsTable";

import { TitleWithDivider } from "../../common/TitleWithDivider";
import WarningModal from "../../components/Modals/WarningModal";
import { SearchBar } from "../../components/SearchBar/SearchBarTemplate";
import { HeaderItems } from "../../common/HeaderItems";
import { getUsersData } from "../../services/API/Requests";
import LoadingIcon from "../../common/LoadingIcon";
import FilterModal from "../../components/Modals/FilterSelectModal";
import PaginationControls from "../../services/PaginationControls";
import axios, { AxiosInstance } from "axios";
import { store } from "../../services/StateManagement/Store";
import { error_message } from "../../constants/Errors";
import messages from "../../constants/Messages";
import { USER_TYPE } from "../../constants/Enums";
import { KAKELY_PATHS } from "../../constants/NavigationPaths";
import { contactHeaderMapping } from "../../services/TranslateCsvHeaders";
import handleDownloadCSV from "../../services/CsvDownloadHandler";
import { TranslateErrorMessage } from "../../services/ErrorMessages";
import { USER } from "../../types/user";
import pageHeaders from "../../constants/PageHeaders";

const KakelyMainPage: React.FC = () => {
  const deleteModalTitle: string = "削除確認";
  const deleteModalBody: string = "電話帳を削除します。よろしいですか？";
  const deleteModalConfirm: string = "削除する";

  const filename: string = "名分の電話帳のユーザー情報_";

  const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);
  const [isFiltered, setIsFiltered] = useState<boolean>(false);
  const [selectedContacts, setSelectedContacts] = useState<number[]>([]);
  const [allSelected, setAllSelected] = useState<boolean>(false);

  const nonSelectedToast = () => toast.error(error_message.user.not_selected);
  const userDeletedToast = (userNum) =>
    toast.success(messages.user.deleted(userNum));

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [contactData, setContactData] = useState<USER[]>([]);
  const [downloadContactData, setDownloadContactData] = useState<USER[]>([]);

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingAllUsers, setLoadingAllUsers] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [queryString, setQueryString] = useState<string>("");

  const [filters, setFilters] = useState({
    last_name: "",
    first_name: "",
    last_furigana_name: "",
    first_furigana_name: "",
    department: "",
    company_name: "",
    personal_tel: "",
    company_fax: "",
    email: "",
  });

  const errorToast = (response) => toast.error(`${response}`);
  const successToast = (response) => toast.success(`${response}`);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalUsers, setTotalUsers] = useState<number>(0);

  const [itemsPerPage, setItemsPerPage] = useState<number>(5);

  const totalPages: number = Math.ceil(totalUsers / itemsPerPage);

  const userType = USER_TYPE.EXTERNAL;

  useEffect(() => {
    setCurrentPage(1);
  }, [itemsPerPage]);

  // 選択されたユーザーのarray
  const matchingUsers = (selectedIds: number[]) => {
    return contactData?.filter((user) => selectedIds.includes(user.id));
  };

  const handleUserSelect = (userId: number, force = false) => {
    setSelectedContacts((prevSelected) => {
      if (force) {
        return [...prevSelected, userId];
      } else if (prevSelected.includes(userId)) {
        setAllSelected(false);
        return prevSelected.filter((id) => id !== userId);
      } else {
        return [...prevSelected, userId];
      }
    });
  };

  const fetchContactInformation = async ({
    curPage = currentPage,
    itemPerPage = itemsPerPage,
    query = queryString,
  } = {}) => {
    await getUsersData(
      setContactData,
      setTotalUsers,
      setError,
      setLoading,
      curPage,
      itemPerPage,
      query,
      userType,
    );
  };

  useEffect(() => {
    if (downloadContactData.length >= 1) {
      handleDownloadCSV(downloadContactData, filename, contactHeaderMapping);
      setDownloadContactData([]);
    }
  }, [downloadContactData]);

  const handleDownloadClick = async () => {
    if (selectedContacts && selectedContacts.length == 0) {
      await getUsersData(
        setDownloadContactData,
        setTotalUsers,
        setError,
        setLoadingAllUsers,
        null,
        totalUsers,
        null,
        userType,
      );
    } else {
      handleDownloadCSV(
        matchingUsers(selectedContacts),
        filename,
        contactHeaderMapping,
      );
    }
  };

  const handleDeselectAll = () => {
    setAllSelected(false);
    setSelectedContacts([]);
  };

  const handleOpenModal = () => {
    setDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };

  const handleDeleteModalConfirm = () => {
    setDeleteModalOpen(false);
    deleteUserData();
  };

  const handleDelete = () => {
    setDeleteModalOpen(true);
  };

  const deleteUserData = async () => {
    handleDeleteModalClose();
    setLoading(true);
    const apiPrefix: string = import.meta.env.VITE_API_PREFIX;
    let errorFound: boolean = false;
    let usersDeleted = false;

    const api: AxiosInstance = axios.create({
      baseURL: import.meta.env.VITE_SYS_API_URL,
    });

    const header: {
      Authorization: null;
    } = store.getState().api.headers;
    api.defaults.headers = header;

    if (selectedContacts) {
      let errorFound = false;
      let errors: string[] = [];
      const usersToDelete = selectedContacts;
      let failedUsers: number = 0;
      setSelectedContacts([]);
      try {
        const results = await Promise.allSettled(
          usersToDelete.map((user) => api.delete(`${apiPrefix}/users/${user}`)),
        );

        results.forEach((result, index) => {
          if (result.status === "rejected") {
            errorFound = true;
            const error =
              result.reason?.response?.data?.error ||
              result.reason?.response?.data?.error ||
              result.reason.message;
            errors.push(`${error}`);
            console.error(
              `error deleting user ${usersToDelete[index]}:`,
              result.reason,
            );
          }
        });

        setSelectedContacts([]);
        setAllSelected(false);
        await fetchContactInformation();

        if (errorFound) {
          errorToast(TranslateErrorMessage(errors[0]));
          userDeletedToast(usersToDelete.length - failedUsers);
        } else {
          userDeletedToast(usersToDelete.length - failedUsers);
        }

        setCurrentPage(1);
      } catch (err) {
        console.error(error_message.user.delete, err.message);
        setError(err.message);
        errorToast(err.message);
      }

      setLoading(false);
    }
  };

  const onFilteredSearch = () => {
    setFilterModalOpen(true);
  };

  const onFilteredModalClose = () => {
    setFilterModalOpen(false);
  };

  useEffect(() => {
    if (!isFiltered && searchTerm == "") {
      fetchContactInformation({ query: "" });
      setCurrentPage(1);
    }
  }, [isFiltered, searchTerm]);

  const onSearch = () => {
    if (searchTerm) {
      const query: string = searchTerm;
      setQueryString(query);
      setCurrentPage(1);
      fetchContactInformation({ curPage: 1, query: query });
      setIsFiltered(true);
    } else {
      setQueryString("");
      setCurrentPage(1);
      fetchContactInformation({ curPage: 1 });
      setIsFiltered(false);
    }
  };

  const doFilteredSearch = async (filters) => {
    const query: URLSearchParams = new URLSearchParams();

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        const searchKey = key;
        query.append(searchKey, filters[key]);
      }
    });

    setQueryString(query.toString());

    const queryString = query.toString() ? `&${query.toString()}` : "";
    setQueryString(queryString);

    setCurrentPage(1);
    fetchContactInformation({ curPage: 1, query: queryString });
    setIsFiltered(true);
  };

  const handlePageChange = async (newPage: number) => {
    fetchContactInformation({ curPage: newPage });
    setCurrentPage(newPage);
  };

  useEffect(() => {
    const updateUserData = async () => {
      fetchContactInformation({ curPage: 1 });
      setCurrentPage(1);
    };

    updateUserData();
  }, [itemsPerPage]);

  const headerItems = [
    {
      icon: {
        width: 24,
        height: 24,
        viewBox: "0 0 24 24",
        path: "M16.9996 11V3M12.9996 7H20.9996M10.2266 13.8631C9.02506 12.6615 8.07627 11.3028 7.38028 9.85323C7.32041 9.72854 7.29048 9.66619 7.26748 9.5873C7.18576 9.30695 7.24446 8.96269 7.41447 8.72526C7.46231 8.65845 7.51947 8.60129 7.63378 8.48698C7.98338 8.13737 8.15819 7.96257 8.27247 7.78679C8.70347 7.1239 8.70347 6.26932 8.27247 5.60643C8.15819 5.43065 7.98338 5.25585 7.63378 4.90624L7.43891 4.71137C6.90747 4.17993 6.64174 3.91421 6.35636 3.76987C5.7888 3.4828 5.11854 3.4828 4.55098 3.76987C4.2656 3.91421 3.99987 4.17993 3.46843 4.71137L3.3108 4.86901C2.78117 5.39863 2.51636 5.66344 2.31411 6.02348C2.08969 6.42298 1.92833 7.04347 1.9297 7.5017C1.93092 7.91464 2.01103 8.19687 2.17124 8.76131C3.03221 11.7947 4.65668 14.6571 7.04466 17.045C9.43264 19.433 12.295 21.0575 15.3284 21.9185C15.8928 22.0787 16.1751 22.1588 16.588 22.16C17.0462 22.1614 17.6667 22 18.0662 21.7756C18.4263 21.5733 18.6911 21.3085 19.2207 20.7789L19.3783 20.6213C19.9098 20.0898 20.1755 19.8241 20.3198 19.5387C20.6069 18.9712 20.6069 18.3009 20.3198 17.7333C20.1755 17.448 19.9098 17.1822 19.3783 16.6508L19.1835 16.4559C18.8339 16.1063 18.6591 15.9315 18.4833 15.8172C17.8204 15.3862 16.9658 15.3862 16.3029 15.8172C16.1271 15.9315 15.9523 16.1063 15.6027 16.4559C15.4884 16.5702 15.4313 16.6274 15.3644 16.6752C15.127 16.8453 14.7828 16.904 14.5024 16.8222C14.4235 16.7992 14.3612 16.7693 14.2365 16.7094C12.7869 16.0134 11.4282 15.0646 10.2266 13.8631Z",
      },
      link: `${KAKELY_PATHS.createContact}`,
      text: "電話帳新規追加",
    },
    {
      icon: {
        width: 22,
        height: 22,
        viewBox: "0 0 22 22",
        path: "M6 19.6621C3.01099 17.9331 1 14.7013 1 10.9999C1 5.47709 5.47715 0.999939 11 0.999939C16.5228 0.999939 21 5.47709 21 10.9999C21 14.7014 18.989 17.9331 16 19.6621M15 11L11 7.00001M11 7.00001L7 11M11 7.00001V21",
      },
      link: `${KAKELY_PATHS.csvUpload}`,
      text: "CSVアップロード",
    },
    {
      icon: {
        width: 24,
        height: 24,
        viewBox: "0 0 24 24",
        path: "M17 3.33782C19.989 5.06687 22 8.29859 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 8.29859 4.01099 5.06687 7 3.33782M8 12L12 16M12 16L16 12M12 16V2",
      },
      onClick: handleDownloadClick,
      text: "CSVダウンロード",
    },
    {
      icon: {
        width: 20,
        height: 22,
        viewBox: "0 0 20 22",
        path: "M14 5V4.2C14 3.0799 14 2.51984 13.782 2.09202C13.5903 1.71569 13.2843 1.40973 12.908 1.21799C12.4802 1 11.9201 1 10.8 1H9.2C8.07989 1 7.51984 1 7.09202 1.21799C6.71569 1.40973 6.40973 1.71569 6.21799 2.09202C6 2.51984 6 3.0799 6 4.2V5M8 10.5V15.5M12 10.5V15.5M1 5H19M17 5V16.2C17 17.8802 17 18.7202 16.673 19.362C16.3854 19.9265 15.9265 20.3854 15.362 20.673C14.7202 21 13.8802 21 12.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V5",
      },
      onClick: selectedContacts.length === 0 ? nonSelectedToast : handleDelete,
      text: "削除",
    },
  ];

  return (
    <>
      <div>
        <TitleWithDivider
          titleText={pageHeaders.KAKELY.main}
          useDivider={true}
        />
        <SearchBar
          searchBarText={"電話帳検索キーワード"}
          buttonLeft={"検索"}
          buttonRight={"絞込検索"}
          setSearchTerm={setSearchTerm}
          onSearch={onSearch}
          onFilterSearch={onFilteredSearch}
          disabled={loading}
        ></SearchBar>
        <HeaderItems
          items={headerItems}
          topPadding={5}
          disabled={loading}
        ></HeaderItems>
        {loadingAllUsers && (
          <div className="absolute inset-0 flex items-center justify-center backdrop-brightness-50 z-50">
            <div className="bg-white bg-opacity-95 p-5 rounded-lg shadow-lg flex items-center justify-center">
              <LoadingIcon bodyText="ユーザー情報を読み込み中。少々を待ちください" />
            </div>
          </div>
        )}
        <div className="mb-5"></div>
        {loading ? (
          <div className="flex items-center justify-center h-40 rounded-xl">
            <LoadingIcon />
          </div>
        ) : (
          <>
            <ContactsTable
              onUserSelect={handleUserSelect}
              onDeselectAll={handleDeselectAll}
              userInformation={contactData}
              users={selectedContacts}
              allSelected={allSelected}
              setAllSelected={setAllSelected}
              itemsPerPage={itemsPerPage}
            />
            <PaginationControls
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              perPage={itemsPerPage}
              displayedItem={"コンタクト"}
              totalItems={totalUsers}
              setPerPage={setItemsPerPage}
            ></PaginationControls>
          </>
        )}
        <WarningModal
          title={deleteModalTitle}
          body={deleteModalBody}
          confirmText={deleteModalConfirm}
          isOpen={deleteModalOpen}
          onConfirm={handleDeleteModalConfirm}
          onRequestClose={handleDeleteModalClose}
        ></WarningModal>
        <FilterModal
          isOpen={filterModalOpen}
          onRequestClose={onFilteredModalClose}
          onApplyFilters={doFilteredSearch}
          filters={filters}
        ></FilterModal>
      </div>
    </>
  );
};

export default KakelyMainPage;
