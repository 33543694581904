import React from "react";
import { TitleWithDivider } from "../../../common/TitleWithDivider";

export const NewCard = ({
  titleText,
  useDivider,
  header,
  bodyLabel,
  bodyContents,
}) => {
  return (
    <>
      <div>
        <TitleWithDivider titleText={titleText} useDivider={useDivider} />

        <div className="border border-text-field-border rounded-xl border-r-1 mt-10">
          <div className="w-full flex mayo-card-header border-b">
            <h3 className="p-4 label-light-blue-xl">{header}</h3>
          </div>

          <div className="mayo-card-body">
            <div className="label-light-blue-xl">{bodyLabel}</div>
            {bodyContents}
          </div>
        </div>
      </div>
    </>
  );
};
