const messages = {
  image: {
    upload_success: "イメージがアップロードされました。",
    icon_hint:
      "※きれいなアイコンにするには、正方形の画像をアップロードしてください",
  },
  csv: {
    hint: " ※ ユーザーにアプリケーションを追加する際、YOBELY または KAKELYから選択できます。APP名は英語の大文字で入力してください。",
    hint_example: "※ 例： 利用アプリ",
    available_apps: "YOBELY,KAKELY",
    app_instruction_title: "アプリ操作方法",
    instructions_user_id_create:
      "※ ユーザーIDが「0」の場合、新規ユーザーが作成されます。",
    instructions_user_id_edit:
      "※ ユーザーIDが既存の値の場合、そのIDのユーザー情報が更新されます。",
    instructions_user_delete:
      "※ 削除フラグ「削除」が「1」の場合、指定したIDのユーザーが削除されます。",
    user_instruction_title: "ユーザー管理CSVの操作方法",
    users_warning:
      "※ 注意：ユーザーの更新および削除は元に戻せません。慎重に操作してください。",
    contacts_instruction_title: "社外電話帳管理CSVの操作方法",
    instructions_contact_id_create:
      "※ IDが「0」または空欄の場合、新規が作成されます。",
    instructions_contact_id_edit:
      "※ IDが既存の値の場合、そのIDの社外電話帳情報が更新されます。",
    instructions_contact_delete:
      "※ 削除フラグ「削除」が「1」の場合、指定したIDの社外電話帳が削除されます。",
    contacts_warning:
      "※ 注意：社外電話帳の更新および削除は元に戻せません。慎重に操作してください。",
  },
  password: {
    success: "パスワードが正常に変更されました。",
    init_success: "パスワードが初期化されました。",
    policy_information:
      "値は英字 (A-Za-z)、数字 (0-9)、アンダースコア (_) のみを含み、5文字以上64文字以内である必要があります。",
  },
  notification: {
    restart: "お知らせが再配信されました。",
    stop: "お知らせが停止されました。",
  },
  keycloak: {
    token_authed: "トークンが認証されました",
    wait_for_init:
      "Keycloakがまだ初期化されていません。しばらくお待ちください...",
  },
  user: {
    deleted: (count) => `${count}のユーザーが削除されました`,
    uploaded: (createdCount, failedCount) =>
      `${createdCount}のユーザーの情報が正常に処理されました。${failedCount}のユーザーの情報が処理に失敗しました。`,
    skipped_deletion: (userNum) =>
      `${userNum} 件のWEBユーザーアカウントが削除されました`,
    no_cards: "名刺データが登録されていません。",
  },
  generic: {
    show_error: "エラーを表示",
    error_modal_title: "エラー表示",
  },
};

export default messages;
