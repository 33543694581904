import React, { Dispatch, useEffect, useMemo, useState } from "react";
import LoadingModal from "../components/Modals/LoadingModal";
import { useDispatch } from "react-redux";
import { setIsAdminApplication } from "../services/StateManagement/AuthSlice";
import { UnknownAction } from "@reduxjs/toolkit";

interface RealmSelectionProps {
  onSelectRealm: (realm: string) => void;
  onSelectAdminRealm: () => void;
  loading: boolean;
  selectedRealm: string;
  error: string;
}

const RealmSelection: React.FC<RealmSelectionProps> = ({
  onSelectRealm,
  onSelectAdminRealm,
  selectedRealm,
  loading,
  error,
}) => {
  const [realm, setRealm] = useState<string>(selectedRealm);

  const dispatch: Dispatch<UnknownAction> = useDispatch();

  const adminRealm: string | null = import.meta.env.VITE_ADMIN_REALM;
  const isAdminApp: boolean = import.meta.env.VITE_ADMIN_APPLICATION === "true";

  dispatch(setIsAdminApplication(isAdminApp));

  useMemo(() => {
    setRealm(selectedRealm);
  }, [selectedRealm]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (isAdminApp) {
        onSelectAdminRealm();
      } else {
        onSelectRealm(realm);
      }
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-mayo-bg-blue via-mayo-white to-mayo-bg-blue">
      <LoadingModal isOpen={loading}></LoadingModal>
      <div className="w-full max-w-lg max-h-lg p-8 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-bold text-center mb-6">
          {isAdminApp ? "Administrator Login" : "Login"}
        </h2>
        <div className="mb-4">
          <label className="block text-md font-medium text-gray-700">
            テナントID
          </label>
          <input
            type="text"
            value={isAdminApp ? adminRealm : realm}
            onKeyDown={handleKeyDown}
            onChange={(e) => setRealm(e.target.value.trim())}
            className={`w-full p-2 border rounded mt-2 ${
              isAdminApp ? "bg-slate-100" : ""
            }`}
            readOnly={isAdminApp}
            placeholder="テナントIDを入力してください"
          />
        </div>

        <div className="flex gap-2.5 justify-center">
          <button
            onClick={
              isAdminApp
                ? () => onSelectAdminRealm()
                : () => onSelectRealm(realm)
            }
            className="bg-blue-500 text-white px-4 py-2 rounded"
            disabled={loading}
          >
            {loading ? "読み込み中..." : "ログイン"}
          </button>
        </div>
        <div>
          <h1 className="mt-5 text-red-500 text-xl flex items-center justify-center">
            {`${error}`}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default RealmSelection;
