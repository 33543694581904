import { EyeIcon, EyeSlashIcon } from "@heroicons/react/16/solid";
import React from "react";
import { useState } from "react";
import { handlePasswordChange } from "../../services/ErrorMessages";
import { UserFieldErrorMessages } from "../../constants/Errors";

interface PasswordItemProps {
  label: string;
  identifier: string;
  password: string;
  errors: boolean;
  modal: boolean;
  setError: (field: any, value: any) => void;
  setPassword: (password: string) => void;
}

const PasswordItem: React.FC<PasswordItemProps> = ({
  label,
  password,
  identifier,
  setError,
  errors,
  modal,
  setPassword,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const verifyPassword = (value) => {
    handlePasswordChange(identifier, value, setPassword, setError);
  };

  return (
    <div className="password-item">
      <>
        <label className="mb-2.5 block font-medium text-mayo-light-gray">
          {label}
        </label>
        <div className="relative">
          <input
            type={showPassword ? "text" : "password"}
            value={password}
            required
            onChange={(e) => verifyPassword(e.target.value)}
            className={modal ? "password-textbox-modal" : "password-textbox"}
          />
          <button
            type="button"
            className={modal ? "password-eye-icon-modal" : "password-eye-icon"}
            onClick={toggleShowPassword}
          >
            {showPassword ? (
              <EyeSlashIcon className="w-6 h-6" />
            ) : (
              <EyeIcon className="w-6 h-6" />
            )}
          </button>
        </div>
        {errors && (
          <p className="text-red-500">{UserFieldErrorMessages.passwordError}</p>
        )}
      </>
    </div>
  );
};

export default PasswordItem;
