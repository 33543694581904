import React from "react";
import { useParams } from "react-router-dom";
import { TitleWithDivider } from "../../common/TitleWithDivider.tsx";
import CreateTenantForm from "../../components/Forms/CreateTenantForm.tsx";
import pageHeaders from "../../constants/PageHeaders.tsx";

const CreateTenantPage: React.FC = () => {
  return (
    <>
      <div>
        <TitleWithDivider
          titleText={pageHeaders.TENANT.add}
          useDivider={true}
        />
        <CreateTenantForm></CreateTenantForm>
      </div>
    </>
  );
};

export default CreateTenantPage;
