import React, { useMemo, useState } from "react";

import { InformationCircleIcon } from "@heroicons/react/24/outline";

import {
  COMPANY_PATHS,
  GENERIC_PATHS,
  KAKELY_PATHS,
  NOTIFICATION_PATHS,
  USER_PATHS,
  WORKJOY_PATHS,
  YOBELY_PATHS,
} from "../../constants/NavigationPaths";
import { sideBarLabels } from "../../constants/SideBarLabels";

const tenant: string | null = localStorage.getItem("realm");
const adminTenant = import.meta.env.VITE_ADMIN_REALM;

export const DefaultSidebarItems = (companyId, availableApps) => {
  const [tenantApps, setTenantApps] = useState<String[]>([]);

  useMemo(() => {
    if (availableApps && Array.isArray(availableApps)) {
      const appNames = availableApps.map((app) => app.app_name);
      setTenantApps(appNames);
    }
  }, [availableApps]);

  return [
    {
      title: sideBarLabels.TENANT.topTitle,
      key: 1,
      icon: (
        <svg
          width="22"
          height="20"
          viewBox="0 0 22 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.5 5H9.25M6.5 9H9.25M6.5 13H9.25M12.75 5H15.5M12.75 9H15.5M12.75 13H15.5M19 19V4.2C19 3.0799 19 2.51984 18.782 2.09202C18.5903 1.71569 18.2843 1.40973 17.908 1.21799C17.4802 1 16.9201 1 15.8 1H6.2C5.07989 1 4.51984 1 4.09202 1.21799C3.71569 1.40973 3.40973 1.71569 3.21799 2.09202C3 2.51984 3 3.0799 3 4.2V19M21 19H1"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      children: [
        {
          key: "company-info-settings",
          title: sideBarLabels.TENANT.companySettings,
          path: `/company/${companyId}/info`,
        },
      ],
    },
    {
      key: 2,
      title: sideBarLabels.USER.topTitle,
      icon: (
        <svg
          width="22"
          height="21"
          viewBox="0 0 22 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 13H7C5.13623 13 4.20435 13 3.46927 13.3045C2.48915 13.7105 1.71046 14.4892 1.30448 15.4693C1 16.2044 1 17.1362 1 19M14.5 1.29076C15.9659 1.88415 17 3.32131 17 5M10.9999 19.5L13.025 19.095C13.2015 19.0597 13.2898 19.042 13.3721 19.0097C13.4452 18.9811 13.5147 18.9439 13.579 18.899C13.6516 18.8484 13.7152 18.7848 13.8426 18.6574L20.5 12C21.0524 11.4477 21.0523 10.5523 20.5 9.99997C19.9477 9.4477 19.0523 9.44771 18.5 9.99999L11.8425 16.6575C11.7152 16.7848 11.6516 16.8484 11.601 16.921C11.5561 16.9853 11.5189 17.0548 11.4902 17.1278C11.458 17.2102 11.4403 17.2984 11.405 17.475L10.9999 19.5ZM12.5 5C12.5 7.20914 10.7091 9 8.5 9C6.29086 9 4.5 7.20914 4.5 5C4.5 2.79086 6.29086 1 8.5 1C10.7091 1 12.5 2.79086 12.5 5Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      children: [
        {
          key: "user-view",
          title: sideBarLabels.USER.view,
          path: `${USER_PATHS.main}`,
        },
        {
          key: "user-add",
          title: sideBarLabels.USER.create,
          path: `${USER_PATHS.createUser}`,
        },
        {
          key: "user-csv",
          title: sideBarLabels.USER.csvUpload,
          path: `${USER_PATHS.csvUpload}`,
        },
      ],
    },
    tenantApps.includes("KAKELY") && {
      key: 3,
      title: sideBarLabels.KAKELY.topTitle,
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.7143 17.4V20.28M20 21H6.28571C5.14286 21 4 20.28 4 18.84M4 18.84C4 17.4 5.14286 16.68 6.28571 16.68H20V3H6.28571C5.14286 3 4 4.08 4 5.16V18.84Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      children: [
        {
          key: "department-csv",
          title: sideBarLabels.KAKELY.departmentView,
          path: `${KAKELY_PATHS.allDepartmentSettings}`,
        },
        {
          key: "phonebook-view",
          title: sideBarLabels.KAKELY.phonebookView,
          path: `${KAKELY_PATHS.main}`,
        },
        {
          key: "phonebook-csv",
          title: sideBarLabels.KAKELY.csvUpload,
          path: `${KAKELY_PATHS.csvUpload}`,
        },
      ],
    },
    {
      key: 6,
      title: sideBarLabels.GENERIC.infoTopTitle,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
          />
        </svg>
      ),
      path: `${GENERIC_PATHS.home}`,
      state: { fromHomePage: true },
    },
  ].filter(Boolean);
};

// sidebar items used for admin users
export const AdminSidebarItems = (companyId) => [
  {
    title: sideBarLabels.TENANT.topTitle,
    key: 10,
    icon: (
      <svg
        width="22"
        height="20"
        viewBox="0 0 22 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.5 5H9.25M6.5 9H9.25M6.5 13H9.25M12.75 5H15.5M12.75 9H15.5M12.75 13H15.5M19 19V4.2C19 3.0799 19 2.51984 18.782 2.09202C18.5903 1.71569 18.2843 1.40973 17.908 1.21799C17.4802 1 16.9201 1 15.8 1H6.2C5.07989 1 4.51984 1 4.09202 1.21799C3.71569 1.40973 3.40973 1.71569 3.21799 2.09202C3 2.51984 3 3.0799 3 4.2V19M21 19H1"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    children: [
      {
        key: "company-management-view",
        title: sideBarLabels.TENANT.viewCompanies,
        path: `${COMPANY_PATHS.listCompanies}`,
      },
      {
        key: "company-settings",
        title: sideBarLabels.TENANT.companySettings,
        path: `/company/${companyId}/manage`,
      },
      ...(tenant === adminTenant
        ? [
            {
              title: sideBarLabels.TENANT.createCompany,
              path: `${COMPANY_PATHS.createCompany}`,
            },
          ]
        : []),
    ],
  },
  {
    key: 11,
    title: sideBarLabels.USER.topTitle,
    icon: (
      <svg
        width="22"
        height="21"
        viewBox="0 0 22 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 13H7C5.13623 13 4.20435 13 3.46927 13.3045C2.48915 13.7105 1.71046 14.4892 1.30448 15.4693C1 16.2044 1 17.1362 1 19M14.5 1.29076C15.9659 1.88415 17 3.32131 17 5M10.9999 19.5L13.025 19.095C13.2015 19.0597 13.2898 19.042 13.3721 19.0097C13.4452 18.9811 13.5147 18.9439 13.579 18.899C13.6516 18.8484 13.7152 18.7848 13.8426 18.6574L20.5 12C21.0524 11.4477 21.0523 10.5523 20.5 9.99997C19.9477 9.4477 19.0523 9.44771 18.5 9.99999L11.8425 16.6575C11.7152 16.7848 11.6516 16.8484 11.601 16.921C11.5561 16.9853 11.5189 17.0548 11.4902 17.1278C11.458 17.2102 11.4403 17.2984 11.405 17.475L10.9999 19.5ZM12.5 5C12.5 7.20914 10.7091 9 8.5 9C6.29086 9 4.5 7.20914 4.5 5C4.5 2.79086 6.29086 1 8.5 1C10.7091 1 12.5 2.79086 12.5 5Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    children: [
      {
        key: "view-users",
        title: sideBarLabels.USER.view,
        path: `${USER_PATHS.main}`,
      },
      {
        key: "create-user",
        title: sideBarLabels.USER.create,
        path: `${USER_PATHS.createUser}`,
      },
    ],
  },
  {
    key: 12,
    title: sideBarLabels.NOTIFICATION.topTitle,
    icon: (
      <svg
        width="20"
        height="22"
        viewBox="0 0 20 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.35419 20C8.05933 20.6224 8.98557 21 10 21C11.0145 21 11.9407 20.6224 12.6458 20M16 7C16 5.4087 15.3679 3.88258 14.2427 2.75736C13.1174 1.63214 11.5913 1 10 1C8.40872 1 6.8826 1.63214 5.75738 2.75736C4.63216 3.88258 4.00002 5.4087 4.00002 7C4.00002 10.0902 3.22049 12.206 2.34968 13.6054C1.61515 14.7859 1.24788 15.3761 1.26134 15.5408C1.27626 15.7231 1.31488 15.7926 1.46179 15.9016C1.59448 16 2.19261 16 3.38887 16H16.6112C17.8074 16 18.4056 16 18.5382 15.9016C18.6852 15.7926 18.7238 15.7231 18.7387 15.5408C18.7522 15.3761 18.3849 14.7859 17.6504 13.6054C16.7795 12.206 16 10.0902 16 7Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    children: [
      {
        title: sideBarLabels.NOTIFICATION.view,
        key: "view-notifications",
        path: `${NOTIFICATION_PATHS.main}`,
        state: { fromHomePage: false },
      },
      {
        title: sideBarLabels.NOTIFICATION.create,
        path: `${NOTIFICATION_PATHS.createNotification}`,
      },
    ],
  },
];

export const myUserWithPasswordItems = (userId) => [
  {
    key: 8,
    title: sideBarLabels.USER.personalTopTile,
    icon: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.25 15C4.00184 13.1419 6.38026 12 9 12C11.6197 12 13.9982 13.1419 15.75 15M12.375 5.625C12.375 7.48896 10.864 9 9 9C7.13604 9 5.625 7.48896 5.625 5.625C5.625 3.76104 7.13604 2.25 9 2.25C10.864 2.25 12.375 3.76104 12.375 5.625Z"
          stroke="white"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    children: [
      {
        key: "current-user",
        title: sideBarLabels.USER.myPage,
        path: `${USER_PATHS.myPage}`,
      },
      {
        key: "password-change",
        title: sideBarLabels.USER.passwordChange,
        path: `${USER_PATHS.passwordReset}`,
      },
    ],
  },
];
