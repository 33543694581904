import React, { useState, useEffect, useRef } from "react";
import { MagnifyingGlassIcon, XCircleIcon } from "@heroicons/react/24/solid";
import useClickOutside from "./ClickOutsideHandler";
import {
  getFilteredBroadcastGroupData,
  getYoberyBroadcastGroup,
} from "../../services/API/Requests";
import useDrag from "./DragHandler";
import { BROADCASTGROUP } from "../../types/broadcastGroup";
import { error_message } from "../../constants/Errors";

interface WorkGroupModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onSelectGroup: (group) => void;
}

const GroupModal: React.FC<WorkGroupModalProps> = ({
  isOpen,
  onSelectGroup,
  onRequestClose,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [groups, setGroups] = useState<BROADCASTGROUP[] | null>([]);
  const [filteredGroups, setFilteredGroups] = useState<BROADCASTGROUP[] | null>(
    [],
  );

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  const modalRef = useRef<HTMLDivElement>(null);

  const { position, handleMouseDown } = useDrag(modalRef, isOpen);
  useClickOutside(isOpen, onRequestClose, modalRef);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await getYoberyBroadcastGroup(setGroups, setLoading, setError);
      } catch (err) {
        console.error(err);
      } finally {
        setSearchTerm("");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (searchTerm === "") {
      setFilteredGroups(groups);
    }
  }, [searchTerm, groups]);

  const doSearch = async () => {
    try {
      setLoading(true);
      if (!searchTerm) {
        setFilteredGroups(groups);
      } else {
        const query = `broadcast_group=${searchTerm}`;
        await getFilteredBroadcastGroupData(
          setFilteredGroups,
          setError,
          setLoading,
          query,
        );
      }
    } catch (error) {
      setError(error_message.yobely.search);
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-opacity-50 flex items-center justify-center backdrop-brightness-50">
      <div
        ref={modalRef}
        onMouseDown={handleMouseDown}
        style={{
          transform: `translate(${position.x}px, ${position.y}px)`,
        }}
        className="border border-black-10 bg-white rounded-xl w-200"
      >
        <div className="mayo-card-header p-4 flex justify-between items-center">
          <h2 className="text-lg font-semibold">一斉発信グループ選択</h2>
          <button onClick={onRequestClose} className="text-xl hover:opacity-50">
            <svg
              className="ml-2 fill-body hover:fill-primary"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <XCircleIcon></XCircleIcon>
            </svg>
          </button>
        </div>

        <div className="ml-10 mt-5">
          <div>
            <div className="flex items-center">
              <div className="relative">
                <svg
                  className="absolute left-2 top-1/2 -translate-y-1/2 fill-body hover:fill-primary"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <MagnifyingGlassIcon />
                </svg>
                <input
                  type="text"
                  className="search-bar pl-10"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <button
                className="ml-3 mr-8 bg-button-light-blue text-white px-5 py-4 rounded-xl flex space-x-4 items-center hover:opacity-50"
                onClick={doSearch}
              >
                {"グループ検索"}
              </button>
            </div>
          </div>
        </div>

        <div className="mb-6"></div>
        <div className="mt-10 px-5">
          <table className="w-full">
            <tbody>
              {filteredGroups?.length === 0 ? (
                <td className="flex justify-between items-center p-5 text-xl text-black">
                  {error_message.yobely.not_found}
                </td>
              ) : (
                filteredGroups?.map((group) => (
                  <React.Fragment key={group.broadcast_group_id}>
                    <tr className="flex justify-between items-center mb-4 gap-2.5">
                      <td className="text-xl text-black">
                        {group.broadcast_group_name}
                      </td>
                      <td>
                        <button
                          className="button-small-white text-white mr-2 py-1 px-4 rounded hover:opacity-50"
                          onClick={() => onSelectGroup(group)}
                        >
                          選択
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="divider"></div>
                      </td>
                    </tr>
                  </React.Fragment>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default GroupModal;
