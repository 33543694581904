import React from "react";
import { NOTIFICATION } from "../../../types/notification";

interface CardDataStatsProps {
  notificationData: NOTIFICATION | undefined;
}

const ViewNotificationCard: React.FC<CardDataStatsProps> = ({
  notificationData,
}) => {
  const renderContentWithLineBreaks = (content: string | undefined) => {
    if (!content) return null;
    return content.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <div className="rounded-lg border border-stroke bg-white py-6 px-3.5">
      <div className="mt-2 flex items-end justify-between">
        <div className="w-full ml-5">
          <>
            <div className="w-full mb-4">
              <h2 className="text-font-label-blue font-bold text-xl">
                配信日時
              </h2>
              <p className="text-title-md text-black ">
                {notificationData?.created_at.split("T")[0]}
              </p>
              <div className="divider mt-5"></div>
            </div>

            <div>
              <h2 className="text-font-label-blue font-bold text-xl">
                タイトル
              </h2>
              <p className="text-title-md text-black">
                {notificationData?.title}
              </p>
            </div>

            <div className="divider mt-5"></div>

            <div className="w-full mb-4">
              <h2 className="text-font-label-blue font-bold text-xl">内容</h2>
              <p className="text-title-md text-black">
                {renderContentWithLineBreaks(notificationData?.content)}
              </p>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default ViewNotificationCard;
