import React, { useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CsvUploader } from "../../common/UploadCsvTemplate";
import {
  getUploadStatus,
  postContactArray,
  postContactArrayCSV,
  postUserDataArrayCSV,
} from "../../services/API/Requests";
import { TranslateErrorMessage } from "../../services/ErrorMessages";
import LoadingIcon from "../../common/LoadingIcon";
import { GetCurrentDate } from "../../services/Utilities";
import { contactHeaderMapping } from "../../services/TranslateCsvHeaders";
import { error_message } from "../../constants/Errors";
import messages from "../../constants/Messages";
import { USER_TYPE } from "../../constants/Enums";
import { KAKELY_PATHS } from "../../constants/NavigationPaths";
import pageHeaders from "../../constants/PageHeaders";

const ContactCsvUploadPage: React.FC = () => {
  const navigate: NavigateFunction = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const currentDate = GetCurrentDate();

  const sampleData = [
    {
      // id: 0,
      first_name: "花子",
      last_name: "佐藤",
      first_furigana_name: "はなこ",
      last_furigana_name: "さとう ",
      first_kana_name: "HANAKO",
      last_kana_name: "SATO",
      email: "hanako.sato@example.com",
      position_name: "人事部マネージャー",
      company_name: "テスト株式会社",
      company_tel: "09023456789",
      company_fax: "09023456319",
      personal_tel: "09023456789",
      company_address: "東京都千代田区神田1-2-3",
      company_home_page: "https://www.exsample.com",
      // delete: 0
    },
  ];

  const expectedHeaders = [
    "id",
    "漢字（姓）",
    "漢字（名）",
    "かな（名）（任意）",
    "かな（姓）（任意）",
    "英字（名）（任意）",
    "英字（姓）（任意）",
    "メールアドレス（任意）",
    "役職名 （任意）",
    "企業名 （任意）",
    "企業電話番号 （任意）",
    "企業FAX番号 （任意）",
    "電話番号（任意）",
    "企業の住所（任意）",
    "企業ホームページ （任意）",
    "削除フラグ",
  ];

  const noCsvImportError = () => toast.error(error_message.csv.no_data);
  const uploadCsvDataToast = (message) => toast.success(message);
  const failCsvDataToast = (error) => toast.error(`${error}`);
  const [csvData, setCsvData] = useState<[]>([]);
  const [failedUsers, setFailedUsers] = useState<[]>();

  const [finalResult, setFinalResult] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [procesedCount, setProcesedCount] = useState(0);

  const handleCsvUpload = async () => {
    if (csvData.length === 0) {
      noCsvImportError();
      return;
    }

    const userDataArray = csvData.map((userInfo) => {
      const user = {
        id: userInfo.id,
        last_name: userInfo.last_name,
        first_name: userInfo.first_name,
        last_kana_name: userInfo.last_kana_name,
        first_kana_name: userInfo.first_kana_name,
        last_furigana_name: userInfo.last_furigana_name,
        first_furigana_name: userInfo.first_furigana_name,
        email: userInfo.email,
        user_type: USER_TYPE.EXTERNAL,
        is_display: userInfo.is_display,
        position_name: userInfo.position_name,
        company_name: userInfo.company_name,
        company_tel: userInfo.company_tel,
        company_fax: userInfo.company_fax,
        personal_tel: userInfo.personal_tel,
        company_address: userInfo.company_address,
        company_home_page: userInfo.company_home_page,
        delete: userInfo.delete,
      };

      return { userInfo: user };
    });

    const postUserInformation = async (userDataArray) => {
      let response;
      try {
        response = await postContactArray(userDataArray, setLoading, setError);
        if (
          response.responseMessage.status >= 300 ||
          response.responseMessage.status == null
        ) {
          const errorMessage = TranslateErrorMessage(response.responseMessage);
          setFailedUsers(response.failed_users);
          console.error(errorMessage);
          setError(errorMessage);
          failCsvDataToast(response.failed_users.error);
          return;
        } else if (response.responseMessage.status == 202) {
          response = await pollForStatus(
            response.request_id,
            userDataArray.length,
          );
        } else {
          uploadCsvDataToast(messages.user.uploaded(1, 0));
        }
        setFailedUsers(response.failed_users);
      } catch (error) {
        if (userDataArray.length == 1 && response.failed_users) {
          failCsvDataToast(response.failed_users.error);
          return;
        }

        if (response && response.failed_users) {
          setFailedUsers(response.failed_users);
        }

        const errorMessage = TranslateErrorMessage(response.responseMessage);
        console.error(errorMessage);
        setError(errorMessage);

        const toastFunction =
          response.created_users.length <= 0
            ? uploadCsvDataToast
            : failCsvDataToast;

        const createdCount =
          response.created_users.length ||
          (response.created_users.length ? 1 : 0);
        const failedCount =
          response.failed_users.length ||
          (response.created_users.length ? 0 : 1);

        toastFunction(messages.user.uploaded(createdCount, failedCount));
      }
    };
    await postUserInformation(userDataArray);
  };

  const onCancel = () => {
    navigate(KAKELY_PATHS.main);
  };

  const pollForStatus = async (requestId, totalUsers) => {
    const POLL_INTERVAL = 1000;
    const TIMEOUT = 60000;
    const startTime = Date.now();

    setIsProcessing(true);

    const poll = async () => {
      try {
        const statusResponse = await getUploadStatus(requestId, totalUsers);

        const { message, processed_count, total_users } = statusResponse.data;

        setProcesedCount(processed_count);

        if (message !== "still processing" && processed_count === total_users) {
          setIsProcessing(false);
          processResult(statusResponse.data);
          return statusResponse.data;
        }

        if (Date.now() - startTime > TIMEOUT) {
          setIsProcessing(false);
          console.error("timeout: status polling stopped.");
          return;
        }

        setTimeout(poll, POLL_INTERVAL);
      } catch (error) {
        console.error("error during status polling:", error);
        setIsProcessing(false);
      }
    };

    poll();
  };

  const processResult = (finalResult) => {
    if (finalResult.failed_users.length > 0) {
      failCsvDataToast(
        messages.user.uploaded(
          finalResult.created_users.length,
          finalResult.failed_users.length,
        ),
      );
    } else {
      uploadCsvDataToast(
        messages.user.uploaded(
          finalResult.created_users.length,
          finalResult.failed_users.length,
        ),
      );
    }

    finalResult.failed_users?.forEach((user) => {
      const translatedMessage = TranslateErrorMessage(user.error);
      user.error = translatedMessage;
    });

    setFailedUsers(finalResult.failed_users);
  };

  return (
    <div className="relative">
      {(loading || isProcessing) && (
        <div className="absolute inset-0 flex items-center justify-center z-50">
          <div className="bg-white bg-opacity-80 p-5 rounded-lg shadow-lg flex items-center justify-center">
            <LoadingIcon
              bodyText={
                isProcessing
                  ? `${procesedCount || 0} / ${csvData.length || 0}`
                  : ""
              }
            />
          </div>
        </div>
      )}
      <CsvUploader
        titleText={pageHeaders.KAKELY.csvUpload}
        expectedHeaders={expectedHeaders}
        uploadHandler={handleCsvUpload}
        onCancel={onCancel}
        csvData={csvData}
        setCsvData={setCsvData}
        sampleData={sampleData}
        failedUsers={failedUsers}
        templateName={`電話帳用CSVサンプル_${currentDate}`}
        headerMapping={contactHeaderMapping}
      />
    </div>
  );
};

export default ContactCsvUploadPage;
