import {
  ChevronDownIcon,
  ChevronUpIcon,
  XCircleIcon,
} from "@heroicons/react/16/solid";
import React, { useRef, useState, useEffect } from "react";
import useClickOutside from "./ClickOutsideHandler";
import useDrag from "./DragHandler";
import {
  tenantSearchFilters,
  userSearchFilters,
} from "../../constants/SearchFilters";

interface FilterModalProps {
  isOpen: boolean;
  filters: Record<string, string>;
  type?: string;
  onRequestClose: () => void;
  onApplyFilters: (filters: Record<string, string>) => void;
}

interface FilterModalProps {
  isOpen: boolean;
  filters: Record<string, string>;
  type?: string;
  onRequestClose: () => void;
  onApplyFilters: (filters: Record<string, string>) => void;
}

const FilterModal: React.FC<FilterModalProps> = ({
  isOpen,
  filters: externalFilters,
  type = "user",
  onRequestClose,
  onApplyFilters,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [filters, setFilters] =
    useState<Record<string, string>>(externalFilters);
  const [expandedSections, setExpandedSections] = useState<
    Record<string, boolean>
  >({});
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { position, handleMouseDown, handleMouseUp } = useDrag(
    modalRef,
    isOpen,
  );
  useClickOutside(isOpen, onRequestClose, modalRef);

  useEffect(() => {
    if (externalFilters) {
      setFilters(externalFilters);
    }
  }, [externalFilters]);

  if (!isOpen) return null;

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    field: string,
  ) => {
    setFilters({ ...filters, [field]: e.target.value });
  };

  const handleApplyFilters = () => {
    onApplyFilters(filters);
    onRequestClose();
  };

  const handleClearFilters = () => {
    onApplyFilters(externalFilters);
    setFilters(externalFilters);
  };

  const toggleSection = (key: string) => {
    setExpandedSections((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center backdrop-brightness-50 z-[1000]">
      <div
        ref={modalRef}
        onMouseDown={(event) => handleMouseDown(event)}
        onMouseUp={handleMouseUp}
        style={{
          transform: `translate(${position.x}px, ${position.y}px)`,
        }}
        className="bg-white border border-text-field-border rounded-lg sm:w-1/3 md:w-1/2 max-h-screen overflow-auto"
      >
        <div className="mayo-card-header p-4 flex justify-between items-center">
          <h2 className="text-lg font-semibold">フィルター選択</h2>
          <button onClick={onRequestClose} className="text-xl hover:opacity-50">
            <svg
              className="ml-2 fill-body hover:fill-primary"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <XCircleIcon />
            </svg>
          </button>
        </div>

        <div className="p-4">
          {Object.keys(filters).map((key) => (
            <div key={key} className="mb-4">
              <div
                className="flex justify-between items-center cursor-pointer mb-2"
                onClick={() => toggleSection(key)}
              >
                <label className="block text-sm font-medium text-gray-700">
                  {getLabelForKey(key, type)}
                </label>
                {expandedSections[key] ? (
                  <ChevronUpIcon className="h-5 w-5 text-gray-500" />
                ) : (
                  <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                )}
              </div>
              <div className="divider"></div>

              {expandedSections[key] && (
                <div>
                  {key === "app_name" || key === "availableApps" ? (
                    <select
                      value={filters[key]}
                      onChange={(e) => handleInputChange(e, key)}
                      onFocus={() => setIsDropdownOpen(true)}
                      onBlur={() => setIsDropdownOpen(false)}
                      className="mt-1 w-full border rounded-md p-2"
                    >
                      <option value="">アプリ名</option>
                      <option value="KAKELY">KAKELY</option>
                      <option value="YOBELY">YOBELY</option>
                      <option value="WorkJoy">WorkJoy</option>
                    </select>
                  ) : (
                    <input
                      type={key === "email" ? "email" : "text"}
                      value={filters[key]}
                      onChange={(e) => handleInputChange(e, key)}
                      className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                    />
                  )}
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="flex justify-end gap-2.5 mb-5 mr-4">
          <button
            onClick={handleClearFilters}
            className="button-small-white border-text-field-border px-4 py-2"
          >
            フィルター削除
          </button>
          <button
            onClick={onRequestClose}
            className="button-small-white border-text-field-border px-4 py-2"
          >
            キャンセル
          </button>
          <button
            onClick={handleApplyFilters}
            className="bg-blue-500 hover:opacity-50 button-modal px-4 py-2"
          >
            適用
          </button>
        </div>
      </div>
    </div>
  );
};

// function to get labels based on the key and type
const getLabelForKey = (key: string, type: "user" | "tenant") => {
  let labels: Record<string, string>;

  switch (type) {
    case "user":
      labels = userSearchFilters;
      break;

    case "tenant":
      labels = tenantSearchFilters;
      break;

    default:
      labels = {};
  }

  return labels[key] || key;
};

export default FilterModal;
