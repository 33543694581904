import React from "react";

import ContactProfileForm from "../../components/Forms/ContactProfileForm.tsx";
import { TitleWithDivider } from "../../common/TitleWithDivider.tsx";
import pageHeaders from "../../constants/PageHeaders.tsx";

const CreateContactPage: React.FC = () => {
  return (
    <>
      <div>
        <TitleWithDivider
          titleText={pageHeaders.KAKELY.phonebookAdd}
          useDivider={true}
        />
        <ContactProfileForm></ContactProfileForm>
      </div>
    </>
  );
};

export default CreateContactPage;
