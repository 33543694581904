import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { NewCard } from "../Cards/Templates/newCard";
import { ConfirmCancelButtons } from "../Buttons/ConfirmCancelButtons";
import { TENANT } from "../../types/tenant";
import { postTenantAdminData } from "../../services/API/Requests";
import WarningModal from "../Modals/WarningModal";
import { ADMINUSER } from "../../types/adminUser";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BlankAdminUserData } from "../../templates/BlankAdminUserData";
import { BlankTenantData } from "../../templates/BlankTenantData";
import {
  handleCodeChange,
  handleHiraganaKanaChange,
  handleNumericalCheck,
  handleNumericalFieldChange,
  handlePostCodeChange,
  handleSubDirectoryChange,
  phoneNumberChange,
  TranslateErrorMessage,
} from "../../services/ErrorMessages";
import { checkRealmExists } from "../../services/KeyCloak/KeyCloak";
import CreateTenantLicenseTable from "../Tables/CreateTenantLicenseTable";
import { CONTRACT } from "../../types/contract";
import messages from "../../constants/Messages";
import {
  error_message,
  TenantFieldErrorMessages,
  UserFieldErrorMessages,
} from "../../constants/Errors";
import { CONTRACT_STATUS } from "../../constants/Enums";
import { COMPANY_PATHS } from "../../constants/NavigationPaths";
import LoadingIcon from "../../common/LoadingIcon";
import { store } from "../../services/StateManagement/Store";

const CreateTenantForm: React.FC = () => {
  const navigate = useNavigate();
  const id: string | null = localStorage.getItem("realm");
  const [tenantData, setTenantData] = useState<TENANT | undefined>(
    BlankTenantData,
  );
  const [adminUserData, setAdminUserData] = useState<ADMINUSER | undefined>(
    BlankAdminUserData,
  );

  const [contractData, setContractData] = useState<CONTRACT[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const [passwordModalOpen, setPasswordModalOpen] = useState(false);

  const passwordChangeSuccessToast = () =>
    toast.success(messages.password.init_success);

  const errorToast = (response) => toast.error(`${response}`);
  const successToast = (response) => toast.success(`${response}`);

  const warningModalTitle = "パスワード初期化";
  const warningModalBody = "パスワードを初期化します。よろしいですか？";
  const warningModalConfirm = "初期化する";

  const [dateError, setDateError] = useState<boolean>(false);
  const [unitPriceError, setUnitPriceError] = useState<boolean>(false);

  const [warningModalOpen, setWarningModalOpen] = useState<boolean>(false);

  const [errors, setErrors] = useState({
    first_name: false,
    last_name: false,
    first_furigana_name: false,
    last_furigana_name: false,
    name_kana: false,
    admin_kana_name: false,
    postal_code: false,
    address: false,
    phone_number: false,
    fax_number: false,
    code: false,
    sub_directory: false,
    store
  });

  const updateContractData = (
    app_id: number,
    key: keyof CONTRACT,
    value: any,
  ) => {
    setContractData((prevState) =>
      prevState.map((contract) =>
        contract.app_id === app_id ? { ...contract, [key]: value } : contract,
      ),
    );
  };

  const updateTenantData = (key: keyof TENANT, value: string | boolean) => {
    setTenantData((prevState) => {
      if (prevState) {
        return {
          ...prevState,
          [key]: value,
        };
      }
      return prevState;
    });
  };

  const updateAdminData = (key: keyof ADMINUSER, value: string | boolean) => {
    setAdminUserData((prevState) => {
      if (prevState) {
        return {
          ...prevState,
          [key]: value,
        };
      }
      return prevState;
    });
  };

  const updateError = (field, value) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: value,
    }));
  };

  const onCancel = () => {
    navigate(COMPANY_PATHS.listCompanies);
  };

  const generateRandomPassword = (length = 12) => {
    const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowercase = "abcdefghijklmnopqrstuvwxyz";
    const numbers = "0123456789";
    const symbols = "!@#$%^&*()_+";
    const allCharacters = uppercase + lowercase + numbers + symbols;

    let password = "";

    password += uppercase[Math.floor(Math.random() * uppercase.length)];
    password += lowercase[Math.floor(Math.random() * lowercase.length)];
    password += numbers[Math.floor(Math.random() * numbers.length)];
    password += symbols[Math.floor(Math.random() * symbols.length)];

    for (let i = 4; i < length; i++) {
      password +=
        allCharacters[Math.floor(Math.random() * allCharacters.length)];
    }

    password = password
      .split("")
      .sort(() => Math.random() - 0.5)
      .join("");

    return password;
  };

  const handleModalConfirm = () => {
    passwordChangeSuccessToast();

    const newPassword = generateRandomPassword(12);

    if (adminUserData) {
      adminUserData.login_password = newPassword;
    } else {
      console.warn(error_message.admin_user.not_defined);
    }

    setPasswordModalOpen(false);
  };

  const verifyFields = () => {
    return !Object.values(errors).some((error) => error === true);
  };

  const validateDates = () => {
    let hasError: boolean = false;

    const emptyContentError: string = error_message.tenant.date_not_defined;
    const illegalDatesError: string = error_message.tenant.bad_input;

    contractData.forEach((contract) => {
      if (contract.status === CONTRACT_STATUS.ACTIVE) {
        if (!contract.start_date) {
          hasError = true;
          errorToast(emptyContentError);
          setError(emptyContentError);
        }

        if (
          contract.end_date &&
          new Date(contract.start_date) > new Date(contract.end_date)
        ) {
          hasError = true;
          errorToast(illegalDatesError);
          setError(illegalDatesError);
        }
      }
    });

    setDateError(hasError);
    return hasError;
  };

  const validateUnitPrice = () => {
    const regex: RegExp = /^[0-9]*$/;
    let hasError: boolean = false;

    const emptyContentError: string = error_message.tenant.blank_unit_price;
    const illegalCharactersError: string = error_message.tenant.bad_unit_price;

    contractData.forEach((contract) => {
      if (contract.status === CONTRACT_STATUS.ACTIVE) {
        const unitPriceString: string = contract.unit_price.toString();
        if (unitPriceString === "0") {
          hasError = true;
          errorToast(emptyContentError);
        }
        if (!regex.test(unitPriceString)) {
          hasError = true;
          errorToast(illegalCharactersError);
        }
      }
    });

    setUnitPriceError(hasError);
    return hasError;
  };

  const handleSubmit = async (event) => {
    setError("");
    event.preventDefault();
    const fields: boolean = verifyFields();
    const realmExists: boolean = await checkRealmExists(tenantData?.code);
    const validDates: boolean = validateDates();
    let response;

    if (!fields) {
      errorToast(error_message.generic.bad_field);
      return;
    } else if (adminUserData?.login_password == "") {
      errorToast(error_message.tenant.exists(tenantData?.code));
      errorToast(error_message.password.must_init);
      return;
    }

    if (realmExists) {
      errorToast(error_message.tenant.exists(tenantData?.code));
      setError(error_message.tenant.exists(tenantData?.code));
      return;
    }

    if (validDates) {
      return;
    }

    if (!validateUnitPrice) {
      return;
    }

    const realm: string | undefined = tenantData?.code;

    event.preventDefault();
    postTenantAdminData(
      tenantData,
      adminUserData,
      contractData,
      setLoading,
      setError,
    )
      .then((response) => {
        if (response.status == 200) {
          successToast(response.message);
        } else {
          const errorMessage = TranslateErrorMessage(response.message);
          console.error(errorMessage);
          setError(errorMessage);
          errorToast(errorMessage);
        }
      })
      .catch((error) => {
        const errorMessage = TranslateErrorMessage(error.message);
        console.error(errorMessage);
        setError(errorMessage);
        errorToast(errorMessage);
      });
  };

  const handleOpenWarningModal = () => {
    setWarningModalOpen(true);
  };

  const handleCloseWarningModal = () => {
    setWarningModalOpen(false);
  };
  return (
    <div>
      {loading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center backdrop-brightness-50 min-h-screen">
          <div className="bg-white bg-opacity-95 p-5 rounded-lg shadow-lg flex items-center justify-center">
            <LoadingIcon />
          </div>
        </div>
      )}
      <div>
        <div className="flex flex-row justify-end">
          {error && (
            <button
              className="button-default"
              type="button"
              onClick={handleOpenWarningModal}
            >
              {messages.generic.show_error}
            </button>
          )}
        </div>

        <form
          onSubmit={handleSubmit}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        >
          <NewCard
            header={`契約状態`}
            bodyLabel={""}
            isTable={true}
            isLoading={loading}
            bodyContents={
              <div className="mayo-card-body">
                <CreateTenantLicenseTable
                  contractData={contractData}
                  setContractData={setContractData}
                  updateContractData={updateContractData}
                />
              </div>
            }
          ></NewCard>

          <NewCard
            header={"基本情報"}
            bodyLabel={""}
            isLoading={loading}
            bodyContents={
              <div>
                <h2 className="mb-6 label-light-blue-xl">
                  企業名 <span className="text-red-500">*</span>
                </h2>
                <input
                  type="text"
                  required
                  className="form-text-field-border"
                  value={tenantData?.name}
                  onChange={(e) => updateTenantData("name", e.target.value)}
                />
                <div className="mt-5 divider"></div>

                <h2 className="mb-6 label-light-blue-xl">
                  企業名ふりがな <span className="text-red-500">*</span>
                </h2>
                <input
                  type="text"
                  required
                  className="form-text-field-border"
                  value={tenantData?.name_kana}
                  onChange={(e) => {
                    handleHiraganaKanaChange(
                      "name_kana",
                      e.target.value,
                      updateTenantData,
                      updateError,
                    );
                  }}
                />
                {errors.name_kana && (
                  <p className="text-red-500">
                    {TenantFieldErrorMessages.nameKanaError}
                  </p>
                )}
                <div className="mt-5 divider"></div>

                <h2 className="mb-6 label-light-blue-xl">
                  テナントID <span className="text-red-500">*</span>
                </h2>
                <input
                  type="text"
                  required
                  className="form-text-field-border"
                  value={tenantData?.code}
                  onChange={(e) => {
                    handleCodeChange(
                      "code",
                      e.target.value,
                      updateTenantData,
                      updateError,
                    );
                  }}
                />
                {errors.code && (
                  <p className="text-red-500">
                    {TenantFieldErrorMessages.codeError}
                  </p>
                )}
                <div className="mt-5 divider"></div>

                <h2 className="mb-6 label-light-blue-xl">
                  郵便番号<span className="text-red-500">* </span>
                  （ハイフンなし）
                </h2>
                <input
                  type="text"
                  required
                  placeholder="1234567"
                  className="form-text-field-border"
                  value={tenantData?.postal_code}
                  onChange={(e) => {
                    handlePostCodeChange(
                      "postal_code",
                      e.target.value,
                      updateTenantData,
                      updateError,
                    );
                  }}
                />
                {errors.postal_code && (
                  <p className="text-red-500">
                    {TenantFieldErrorMessages.postcodeError}
                  </p>
                )}
                <div className="mt-5 divider"></div>

                <h2 className="mb-6 label-light-blue-xl">
                  住所 <span className="text-red-500">*</span>
                </h2>
                <input
                  type="text"
                  required
                  className="form-text-field-border"
                  value={tenantData?.address}
                  onChange={(e) => updateTenantData("address", e.target.value)}
                />
                <div className="mt-5 divider"></div>

                <h2 className="mb-6 label-light-blue-xl">
                  電話番号 <span className="text-red-500">*</span>
                </h2>
                <input
                  type="text"
                  required
                  placeholder="08012345678"
                  className="form-text-field-border"
                  value={tenantData?.phone_number}
                  onChange={(e) => {
                    phoneNumberChange(
                      "phone_number",
                      e.target.value,
                      updateTenantData,
                      updateError,
                    );
                  }}
                />
                {errors.phone_number && (
                  <p className="text-red-500">
                    {TenantFieldErrorMessages.telephoneNumberError}
                  </p>
                )}
                <div className="mt-5 divider"></div>

                <h2 className="mb-6 label-light-blue-xl">FAX番号</h2>
                <input
                  type="text"
                  placeholder="08012345678"
                  className="form-text-field-border"
                  value={tenantData?.fax_number}
                  onChange={(e) => {
                    phoneNumberChange(
                      "fax_number",
                      e.target.value,
                      updateTenantData,
                      updateError,
                    );
                  }}
                />
                {errors.fax_number && (
                  <p className="text-red-500">
                    {TenantFieldErrorMessages.faxNumberError}
                  </p>
                )}
                <div className="mt-5 divider"></div>

                <h2 className="mb-6 label-light-blue-xl">企業URL</h2>
                <input
                  type="url"
                  className="form-text-field-border"
                  value={tenantData?.url}
                  onChange={(e) => updateTenantData("url", e.target.value)}
                />
                <div className="mt-5 divider"></div>
              </div>
            }
          ></NewCard>

<NewCard
            header={"ShoppersCloudの設定"}
            bodyLabel={""}
            isLoading={loading}
            bodyContents={
              <div>
                <h2 className="mb-6 label-light-blue-xl">URL</h2>
                <div className="flex ml-5 mb-2 text-xl text-black">
                  <input
                    type="url"
                    className="form-text-field-border"
                    value={tenantData?.shoppers_cloud_url}
                    onChange={(e) =>
                      updateTenantData("shoppers_cloud_url", e.target.value)
                    }
                  />
                </div>

                <div className="mt-5 divider"></div>

                <h2 className="mt-6 mb-6 label-light-blue-xl">Sub-Directory</h2>
                <div className="flex ml-5 mb-2 text-xl text-black">
                  <input
                    type="text"
                    className="form-text-field-border"
                    value={tenantData?.sub_directory}
                    onChange={(e) =>
                      handleSubDirectoryChange(
                        "sub_directory",
                        e.target.value,
                        updateTenantData,
                        updateError,
                      )
                    }
                  />
                </div>
                {errors.sub_directory && (
                  <p className="text-red-500">
                    {TenantFieldErrorMessages.subDirectoryError}
                  </p>
                )}

                <div className="mt-5 divider"></div>

                <h2 className="mt-6 mb-6 label-light-blue-xl">店舗ID</h2>
                <div className="flex ml-5 mb-2 text-xl text-black">
                  <input
                    type="text"
                    className="form-text-field-border"
                    value={tenantData?.store_id}
                    onChange={(e) =>
                      handleNumericalFieldChange(
                        "store_id",
                        e.target.value,
                        updateTenantData,
                        updateError,
                      )
                    }
                  />
                </div>
                {errors.store_id && (
                  <p className="text-red-500">
                    {TenantFieldErrorMessages.storeIdError}
                  </p>
                )}
              </div>
            }
          />

          <NewCard
            header={"セキュリティ設定"}
            bodyLabel={""}
            isLoading={loading}
            bodyContents={
              <div>
                <h2 className="mb-6 label-light-blue-xl">ログイン方法設定</h2>
                <div className="flex ml-5 mb-2 text-xl text-black">
                  <label className="flex items-center mr-5">
                    <input
                      type="checkbox"
                      className="mr-5 w-5 h-5"
                      checked={tenantData?.local_auth}
                      onChange={() =>
                        updateTenantData("local_auth", !tenantData?.local_auth)
                      }
                    />
                    ローカル認証
                  </label>
                </div>

                <div className="mt-5 divider"></div>

                <h2 className="mt-6 mb-6 label-light-blue-xl">
                  電話帳参照制限
                </h2>
                <div className="ml-5 mb-2 text-xl text-black">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="mr-5 w-5 h-5"
                      checked={tenantData?.require_passcode_for_phonebook}
                      onChange={() =>
                        updateTenantData(
                          "require_passcode_for_phonebook",
                          !tenantData?.require_passcode_for_phonebook,
                        )
                      }
                    />
                    電話帳参照時にパスコードの入力を求める
                  </label>
                </div>
              </div>
            }
          />

          <NewCard
            header={"管理者情報"}
            bodyLabel={""}
            isLoading={loading}
            bodyContents={
              <div>
                <div>
                  <h2 className="mb-5 label-light-blue-xl">
                    {" "}
                    名前 <span className="text-red-500">*</span>{" "}
                  </h2>
                  <div className="flex">
                    <div>
                      <h2 className="mb-5 label-light-blue-md"> 姓 </h2>
                      <input
                        type="text"
                        className="form-text-field-border"
                        value={adminUserData?.last_name}
                        required
                        onChange={(e) =>
                          handleNumericalCheck(
                            "last_name",
                            e.target.value,
                            updateAdminData,
                            updateError,
                          )
                        }
                      />
                      {errors.last_name && (
                        <p className="text-red-500">
                          {UserFieldErrorMessages.nameError}
                        </p>
                      )}
                    </div>

                    <div>
                      <h2 className="mb-5 label-light-blue-md"> 名 </h2>
                      <input
                        type="text"
                        className="form-text-field-border"
                        value={adminUserData?.first_name}
                        required
                        onChange={(e) =>
                          handleNumericalCheck(
                            "first_name",
                            e.target.value,
                            updateAdminData,
                            updateError,
                          )
                        }
                      />
                      {errors.first_name && (
                        <p className="text-red-500">
                          {UserFieldErrorMessages.nameError}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mt-5 divider"></div>

                <div>
                  <h2 className="mb-5 label-light-blue-xl"> 氏名ふりがな </h2>
                  <div className="flex">
                    <div>
                      <h2 className="mb-5 label-light-blue-md"> 姓 </h2>
                      <input
                        type="text"
                        className="form-text-field-border"
                        value={adminUserData?.last_furigana_name}
                        onChange={(e) =>
                          handleHiraganaKanaChange(
                            "last_furigana_name",
                            e.target.value,
                            updateAdminData,
                            updateError,
                          )
                        }
                      />
                      {errors.last_furigana_name && (
                        <p className="text-red-500">
                          {UserFieldErrorMessages.nameHiraganaError}
                        </p>
                      )}
                    </div>

                    <div>
                      <h2 className="mb-5 label-light-blue-md"> 名 </h2>
                      <input
                        type="text"
                        className="form-text-field-border"
                        value={adminUserData?.first_furigana_name}
                        onChange={(e) =>
                          handleHiraganaKanaChange(
                            "first_furigana_name",
                            e.target.value,
                            updateAdminData,
                            updateError,
                          )
                        }
                      />
                      {errors.first_furigana_name && (
                        <p className="text-red-500">
                          {UserFieldErrorMessages.nameHiraganaError}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="mt-5 divider"></div>

                <h2 className="mb-6 label-light-blue-xl">
                  メールアドレス <span className="text-red-500">*</span>
                </h2>
                <div className="flex justify-between items-center">
                  <input
                    type="email"
                    required
                    className="form-text-field-border"
                    value={adminUserData?.login_id}
                    onChange={(e) =>
                      updateAdminData("login_id", e.target.value)
                    }
                  />
                  {adminUserData?.login_password && (
                    <div className="flex items-center ml-4 mr-2 text-xl">
                      <span className="mr-2">{`最初のパスワード:`}</span>
                      <span className="font-bold">
                        {adminUserData?.login_password}
                      </span>
                    </div>
                  )}
                  <button
                    className="button-default-white justify-end items-center max-w-50 min-w-50 hover:opacity-50"
                    type="button"
                    onClick={() => setPasswordModalOpen(true)}
                  >
                    パスワード初期化
                  </button>
                </div>
                <div className="mt-5 divider"></div>
              </div>
            }
          ></NewCard>

          <ConfirmCancelButtons
            confirmText={"作成"}
            form={true}
            cancelText={undefined}
            onCancel={onCancel}
          ></ConfirmCancelButtons>

          <WarningModal
            title={warningModalTitle}
            body={warningModalBody}
            confirmText={warningModalConfirm}
            isOpen={passwordModalOpen}
            onConfirm={handleModalConfirm}
            confirmButtonColor={"button-confirm"}
            onRequestClose={() => setPasswordModalOpen(false)}
          ></WarningModal>

          <WarningModal
            title={messages.generic.error_modal_title}
            body={error}
            confirmText={""}
            cancelText="戻る"
            isOpen={warningModalOpen}
            onRequestClose={handleCloseWarningModal}
          ></WarningModal>
        </form>
      </div>
    </div>
  );
};

export default CreateTenantForm;
