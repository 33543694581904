import React, { useState, ChangeEvent, useRef, useEffect } from "react";
import Papa from "papaparse";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TitleWithDivider } from "./TitleWithDivider";
import { ConfirmCancelButtons } from "../components/Buttons/ConfirmCancelButtons";
import FailedUsersModal from "../components/Modals/FailedUsersModal";
import { USER } from "../types/user";
import Encoding from "encoding-japanese";
import { translateAndReorderHeaders } from "../services/TranslateCsvHeaders";
import { error_message } from "../constants/Errors";
import messages from "../constants/Messages";
import BadFieldsModal from "../components/Modals/BadFieldsModal";
import { reset } from "../services/StateManagement/AppSlice";

interface CsvPageProps {
  expectedHeaders: string[];
  uploadHandler: () => void;
  cancelText?: string;
  titleText?: string;
  failedUsers?: [];
  onCancel: () => void;
  sampleData: USER[];
  templateName: string;
  csvData: any[];
  setCsvData: (data: any[]) => void;
  headerMapping: [];
  fromUserPage?: boolean;
}

export const CsvUploader: React.FC<CsvPageProps> = ({
  expectedHeaders,
  uploadHandler,
  cancelText = "Cancel",
  titleText = "CSVアップロード",
  failedUsers = [],
  onCancel,
  templateName,
  sampleData,
  csvData,
  setCsvData,
  headerMapping,
  fromUserPage = false,
}) => {
  const fileInput = useRef<HTMLInputElement | null>(null);

  const [failedUsersModalOpen, setFailedUsersOpen] = useState(false);
  const [failedRowsModalOpen, setFailedRowsModalOpen] = useState(false);

  const [csvFileSubmitted, setCsvFileSubmitted] = useState(false);

  const [files, setFiles] = useState<File[]>([]);
  const [fileReadText, setFileReadText] = useState(
    error_message.csv.no_file_selected,
  );

  const errorToast = (response) => toast.error(`${response}`);
  const [failedUsersArray, setFailedUsersArray] = useState(failedUsers);

  const headerError = () => toast.error(error_message.csv.incorrect_headers);
  const noCsvImportError = () => toast.error(error_message.csv.no_data);
  const [nullErrors, setNullErrors] = useState([]);

  const createReverseMapping = (mapping: { [key: string]: string }) => {
    return Object.fromEntries(
      Object.entries(mapping).map(([key, value]) => [value, key]),
    );
  };

  const resetFields = () => {
    setNullErrors([]);
    setFailedUsersArray([]);
    setCsvData([]);
    setFileReadText(error_message.csv.no_file_selected);
  };
  const reverseHeaderMapping = createReverseMapping(headerMapping);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.target.files || []);
    setFiles(selectedFiles);

    resetFields();

    if (selectedFiles.length > 0) {
      const file = selectedFiles[0];
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);

      reader.onload = () => {
        const arrayBuffer = reader.result as ArrayBuffer;
        const uint8Array = new Uint8Array(arrayBuffer);
        const encoding = Encoding.detect(uint8Array);

        let decodedCsv;
        if (encoding === "SJIS" || encoding === "SHIFT_JIS") {
          const unicodeArray = Encoding.convert(uint8Array, {
            from: "SJIS",
            to: "UNICODE",
            type: "array",
          });
          decodedCsv = Encoding.codeToString(unicodeArray);
        } else {
          decodedCsv = new TextDecoder("utf-8").decode(uint8Array);
        }

        Papa.parse(decodedCsv, {
          header: true,
          skipEmptyLines: true,
          complete: (results) => {
            const parsedHeaders = results.meta.fields;

            const expectedHeadersFromMapping = Object.values(headerMapping);

            const unmatchedHeaders = parsedHeaders?.filter(
              (header) => !expectedHeadersFromMapping.includes(header),
            );
            if (unmatchedHeaders && unmatchedHeaders.length > 0) {
              console.warn("unmatched headers found in CSV:", unmatchedHeaders);
              headerError();
              setCsvData([]);
              return;
            }

            const nullField = results.data.map((row: any, index: number) => {
              const errorRow = validateNullFields(row, index);

              setNullErrors((prevErrors: any) => {
                return errorRow ? [...prevErrors, ...errorRow] : prevErrors;
              });

              return errorRow ? true : false;
            });

            if (nullField.includes(true)) {
              errorToast(error_message.csv.null_fields);
              return;
            }

            const translatedData = results.data.map(
              (row: any, index: number) => {
                const translatedRow: { [key: string]: any } = { index };
                parsedHeaders.forEach((header) => {
                  const englishHeader = reverseHeaderMapping[header] || header;
                  translatedRow[englishHeader] = row[header] || "";
                });
                return translatedRow;
              },
            );
            setCsvData(translatedData);
            setFileReadText(`「${file.name}」が選択されています。`);
          },
          error: (error) => {
            console.error(error_message.csv.parse_error, error);
            errorToast(error_message.csv.parse_error);
            setCsvData([]);
            setFiles([]);
          },
        });
      };

      reader.onerror = () => {
        console.error(error_message.csv.loading);
        errorToast(error_message.csv.loading);
      };
    }

    event.target.value = null;
  };

  const validateNullFields = (user, index) => {
    const errors = [];
    const excludedKeys = [
      "id",
      "利用アプリ",
      "かな（せい）（任意）",
      "かな（めい）（任意）",
      "社員番号（任意）",
      "メールアドレス（任意）",
      "役職（任意）",
      "部署コード（任意）",
      "役職名 （任意）",
      "企業名 （任意）",
      "企業電話番号 （任意）",
      "企業FAX番号 （任意）",
      "電話番号（任意）",
      "企業の住所（任意）",
      "企業ホームページ （任意）",
      "削除フラグ",
      "ログインパスワード",
    ];

    Object.keys(user).forEach((key) => {
      if (
        (!user[key] || user[key].toString().trim() === "") &&
        !excludedKeys.includes(key)
      ) {
        errors.push({
          row: index + 1,
          error: `${key}が必要です`,
        });
      }
    });
    return errors.length > 0 ? errors : null;
  };

  const translateSampleData = (
    sampleData: USER[],
    mapping: { [key: string]: string },
  ) => {
    return sampleData.map((row) => {
      const translatedRow: { [key: string]: any } = {};

      Object.keys(mapping).forEach((key) => {
        if (key in row) {
          translatedRow[mapping[key]] = row[key];
        }
      });

      return translatedRow;
    });
  };

  const handleDownloadSampleCsv = () => {
    const translatedSampleData = translateSampleData(sampleData, headerMapping);

    const filteredHeaders = Object.values(headerMapping);

    const csvString = Papa.unparse(translatedSampleData, {
      columns: filteredHeaders,
    });

    const sjisArray = Encoding.stringToCode(csvString);
    const encodedArray = Encoding.convert(sjisArray, {
      from: "UNICODE",
      to: "SJIS",
    });
    const uint8Array = new Uint8Array(encodedArray);

    const blob = new Blob([uint8Array], {
      type: "text/csv;charset=Shift_JIS;",
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${templateName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const removeEntryFromCsv = (originalIndex) => {
    setCsvData((prev) => {
      return prev.filter((user) => user.index !== originalIndex);
    });
  };

  const handleUpload = () => {
    uploadHandler();
    setCsvFileSubmitted(true);
  };

  useEffect(() => {
    setFailedUsersArray(failedUsers);
  }, [failedUsers]);

  useEffect(() => {
    if (csvData.length <= 0) {
      setFileReadText(error_message.csv.no_file_selected);

      if (csvFileSubmitted) {
        resetFields();
        setCsvFileSubmitted(false);
      }
    }
  }, [csvData]);

  return (
    <div>
      <TitleWithDivider titleText={titleText} useDivider={true} />
      <div className="mt-4 flex justify-between">
        <button
          className="p-3 mb-5 bg-button-light-blue text-white rounded-lg hover:opacity-80"
          onClick={handleDownloadSampleCsv}
        >
          テンプレートCSVをダウンロード
        </button>
        {failedUsersArray.length > 0 && (
          <button
            className="ml-5 mb-2 pl-2 pr-2 items-center justify-center bg-button-light-blue text-white rounded-xl hover:opacity-80"
            onClick={() => setFailedUsersOpen(true)}
          >
            {fromUserPage ? "失敗したユーザーを表示" : "失敗した電話帳を表示"}
          </button>
        )}
        {nullErrors?.length > 0 && (
          <button
            className="ml-5 mb-2 pl-2 pr-2 items-center justify-center bg-button-light-blue text-white rounded-xl hover:opacity-80"
            onClick={() => setFailedRowsModalOpen(true)}
          >
            失敗した行を表示
          </button>
        )}
      </div>
      <div
        className={`flex flex-col p-5 bg-white rounded-lg border border-divider ${
          csvData.length > 0 ? "min-h-30" : ""
        }`}
      >
        <div className="flex">
          <button
            className="p-1 bg-button-light-blue text-white rounded-xl hover:opacity-80"
            onClick={() => fileInput.current?.click()}
          >
            ファイルを選択
          </button>
          <div className="p-5 bg-white rounded-xl mr-5 max-w-full break-words whitespace-pre-line">
            {fileReadText}
          </div>
          <input
            type="file"
            onChange={handleFileChange}
            accept=".csv"
            ref={fileInput}
            style={{ display: "none" }}
          />
        </div>
      </div>
      <ConfirmCancelButtons
        confirmText={"インポート"}
        onConfirm={csvData.length === 0 ? noCsvImportError : handleUpload}
        onCancel={onCancel}
      />

      {fromUserPage ? (
        <div>
          <div>
            <h3 className="text-lg font-bold">
              {messages.csv.app_instruction_title}
            </h3>
            <p className="mt-2">{messages.csv.hint}</p>
            <p className="ml-10 mt-5">{messages.csv.hint_example}</p>
            <p className="ml-18">{messages.csv.available_apps}</p>
            <br />
          </div>

          <div>
            <h3 className="text-lg font-bold">
              {messages.csv.user_instruction_title}
            </h3>
            <p className="mt-2">{messages.csv.instructions_user_id_create}</p>
            <p className="mt-2">{messages.csv.instructions_user_id_edit}</p>
            <p className="mt-2">{messages.csv.instructions_user_delete}</p>
            <p className="text-red-500 text-lg underline mt-5">
              {messages.csv.users_warning}
            </p>
          </div>
        </div>
      ) : (
        <div>
          <h3 className="text-lg font-bold">
            {messages.csv.contacts_instruction_title}
          </h3>
          <p className="mt-2">{messages.csv.instructions_contact_id_create}</p>
          <p className="mt-2">{messages.csv.instructions_contact_id_edit}</p>
          <p className="mt-2">{messages.csv.instructions_contact_delete}</p>
          <p className="text-red-500 text-lg underline mt-5">
            {messages.csv.contacts_warning}
          </p>
        </div>
      )}

      <FailedUsersModal
        removeFromCsv={removeEntryFromCsv}
        failedUsers={failedUsersArray}
        confirmText={""}
        isOpen={failedUsersModalOpen}
        fromUserUpload={fromUserPage}
        onRequestClose={() => setFailedUsersOpen(false)}
      />
      <BadFieldsModal
        badFields={nullErrors}
        confirmText={""}
        isOpen={failedRowsModalOpen}
        onRequestClose={() => setFailedRowsModalOpen(false)}
      />
    </div>
  );
};
