import React from "react";
import LoadingIcon from "../../../common/LoadingIcon";

interface Card {
  header: string;
  bodyLabel: string;
  isTable?: boolean;
  isLoading?: boolean;
  bodyContents: React.ReactNode;
}

export const NewCard: React.FC<Card> = ({
  header,
  bodyLabel,
  isTable,
  isLoading,
  bodyContents,
}) => {
  return (
    <div>
      <div className="border border-text-field-border rounded-xl border-r-1 mt-10">
        <div className="w-full flex mayo-card-header border-b">
          <h3 className="p-4 label-light-blue-xl">{header}</h3>
        </div>

        <div>
          {isLoading ? (
            <LoadingIcon />
          ) : (
            <div
              className={!isTable ? "mayo-card-body" : "bg-white rounded-xl"}
            >
              <div className="label-light-blue-xl">{bodyLabel}</div>
              {bodyContents}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
