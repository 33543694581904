import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import BusinessCardTable from "../Tables/BuisnessCardTable.tsx";
import { NewCard } from "../Cards/Templates/newCard.tsx";
import { ConfirmCancelButtons } from "../Buttons/ConfirmCancelButtons.tsx";
import {
  getContactData,
  getContactHistory,
  postContact,
  putContact,
  uploadCardImage,
} from "../../services/API/Requests.tsx";
import { toast } from "react-toastify";
import {
  handleHiraganaKanaChange,
  handleNumericalCheck,
  phoneNumberChange,
  TranslateErrorMessage,
} from "../../services/ErrorMessages.tsx";
import { USER } from "../../types/user.ts";
import BlankUser from "../../templates/BlankUserData.tsx";
import {
  error_message,
  UserFieldErrorMessages,
} from "../../constants/Errors.tsx";
import { USER_TYPE_STRING } from "../../constants/Enums.tsx";
import WarningModal from "../Modals/WarningModal.tsx";
import messages from "../../constants/Messages.tsx";
import { KAKELY_PATHS } from "../../constants/NavigationPaths.tsx";

const ContactProfileForm = (): React.JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState<string>("");

  const [contactData, setContactData] = useState<USER>();
  const [localContactData, setLocalContactData] = useState<USER>();
  const [contactHistoryData, setContactHistoryData] = useState();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  const [isViewingHistory, setIsViewingHistory] = useState(false);

  const [isExternalUser, setIsExternalUser] = useState<boolean>(false);
  const [userNotFound, setUserNotFound] = useState<boolean>(false);

  const [dateCreated, setDateCreated] = useState<string>("");
  const [implementerLogin, setImplementerLogin] = useState("");

  const errorToast = (response) => toast.error(`${response}`);
  const successToast = (response) => toast.success(`${response}`);

  const [warningModalOpen, setWarningModalOpen] = useState<boolean>(false);

  const [createContact, setCreateContact] = useState(
    id === undefined ? true : false,
  );

  // errors for various user/contact variables
  const [errors, setErrors] = useState({
    name: false,
    first_name: false,
    last_name: false,
    kana_name: false,
    last_kana_name: false,
    first_kana_name: false,
    furigana_name: false,
    first_furigana_name: false,
    last_furigana_name: false,
    personal_tel: false,
    email: false,
    company_name: false,
    company_tel: false,
    company_fax: false,
    position_name: false,
    kana_name_length: false,
  });

  // set the user type based on if we're creating a contact (external)
  // or if it is a user already created from the contact creation
  // otherwise, if it is a user created from users page, type is internal
  useEffect(() => {
    if (contactData?.user_type == USER_TYPE_STRING.EXTERNAL || createContact) {
      setIsExternalUser(true);
    }
  }, [contactData]);

  // get our initial contact page data
  useEffect(() => {
    const fetchContactData = async () => {
      try {
        await getContactData(
          setContactData,
          setLoading,
          setError,
          id,
          setLocalContactData,
        ).then((response) => {
          if (response.status >= 300) {
            setUserNotFound(true);
          } else {
            setUserNotFound(false);
          }
        });
      } catch (error) {
        console.error(error_message.contact.data_load, error);
        setError(error);
      }

      setLoading(false);
    };

    if (!createContact) {
      fetchContactData();
      getContactHistory(setContactHistoryData, setLoading, setError, id);
    } else {
      setLoading(false);
      setContactData(BlankUser);
    }
  }, [id]);

  // get the user/contact's business cards
  const setBusinessCards = (newCardArray) => {
    contactData.card_image_urls = newCardArray;
  };

  // set the date for the creation variable
  useEffect(() => {
    const today: Date = new Date(Date.UTC(2012, 11, 20, 3, 0, 0));
    const stringToday: string = today.toLocaleDateString("ja-JP-u-ca-japanese");
    setDateCreated(stringToday);
  }, []);

  // handle change for the dropdown menu
  const handleDropdownChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
    const index = event.target.selectedIndex - 1;

    if (index <= -1) {
      setIsViewingHistory(false);
      setContactData(localContactData);
    } else {
      setIsViewingHistory(true);
      setContactData(contactHistoryData[index].content?.new);
      setImplementerLogin(contactHistoryData[index].implementer_login);
    }
  };

  // when the cancel button is clicked
  const onCancel = () => {
    navigate(KAKELY_PATHS.main);
  };

  // when a new user creation request is submitted
  const handleCreateNewUser = (event) => {
    event.preventDefault();

    postContact(contactData, setLoading, setError)
      .then((response) => {
        if (response.status == 200) {
          if (
            contactData?.card_image_urls?.length &&
            contactData.card_image_urls.length > 0
          ) {
            handleCardUpload(response.user_id, true);
          }
          successToast(response.message);
          setBusinessCards(contactData?.card_image_urls);
          if (response.user_id) {
            navigate(`/KAKELY/${response.user_id}`);
          }
        } else {
          const errorResponse = TranslateErrorMessage(response.message);
          errorToast(errorResponse);
          setError(errorResponse);
        }
      })
      .catch((error) => {
        const errorResponse = TranslateErrorMessage(error.message);
        errorToast(errorResponse);
        setError(errorResponse);
        errorToast(errorResponse);
      });
  };

  // when a user business card is uploaded
  // TODO: handle error checking
  const handleCardUpload = async (id: number, initalize: boolean) => {
    try {
      await uploadCardImage(id, contactData?.card_image_urls[0], initalize);
    } catch (error) {
      setError(error);
      console.error(error_message.image.upload, error);
    }
  };

  // when a contact update request is submitted
  const handlePutContact = async (event) => {
    event.preventDefault();
    putContact(contactData, setLoading, setError, id)
      .then((response) => {
        if (response.status == 200) {
          successToast(response.message);
          getContactData(
            setContactData,
            setLoading,
            setError,
            id,
            setLocalContactData,
          );
          getContactHistory(setContactHistoryData, setLoading, setError, id);
        } else {
          const errorMessage = TranslateErrorMessage(response.message);
          console.error(errorMessage);
          setError(errorMessage);
          errorToast(errorMessage);
        }
      })
      .catch((error) => {
        const errorMessage = TranslateErrorMessage(response.message);
        console.error(errorMessage);
        setError(errorMessage);
        errorToast(errorMessage);
      });
  };

  // generic function to update user/contact data based on key and value
  const updateUserData = (key: keyof USER, value: string | boolean) => {
    setContactData((prevState) => ({
      ...prevState!,
      [key]: value,
    }));
  };

  // generic function to update error data based on key and value
  const updateError = (field, value) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: value,
    }));
  };

  const handleOpenWarningModal = () => {
    setWarningModalOpen(true);
  };

  const handleCloseWarningModal = () => {
    setWarningModalOpen(false);
  };

  const convertToJapaneseTime = (date: string) => {
    const newDate = new Date(date);

    const jstDate = new Date(newDate.getTime() + 9 * 60 * 60 * 1000);

    const formattedDate = `${jstDate.getFullYear()}年${
      jstDate.getMonth() + 1
    }月${jstDate.getDate()}日　${jstDate.toTimeString().split(" ")[0]}`;

    return formattedDate;
  };

  return (
    <>
      <div>
        {userNotFound ? (
          <>
            <p className="not-found-text">
              {error_message.contact.not_found(id)}
            </p>
          </>
        ) : (
          <form
            onSubmit={createContact ? handleCreateNewUser : handlePutContact}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
          >
            <div className="flex flex-row justify-end">
              {error && (
                <button
                  className="button-default"
                  type="button"
                  onClick={handleOpenWarningModal}
                >
                  {messages.generic.show_error}
                </button>
              )}
            </div>
            <NewCard
              header={"名刺画像"}
              bodyLabel={"名刺画像"}
              isLoading={loading}
              bodyContents={
                <div>
                  <div className="flex">
                    {contactData && contactData.card_image_urls?.length >= 1 ? (
                      <BusinessCardTable
                        userId={contactData?.id}
                        cardData={contactData.card_image_urls?.slice(0, 2)}
                        setData={setBusinessCards}
                        isCreate={createContact}
                        disabled={isViewingHistory}
                      />
                    ) : (
                      <BusinessCardTable
                        cardData={[]}
                        userId={contactData?.id}
                        setData={setBusinessCards}
                        isCreate={createContact}
                        disabled={isViewingHistory}
                      />
                    )}
                  </div>

                  <div className="divider mt-5 mb-6"></div>

                  <h3 className="mt-10 mb-5 label-light-blue-xl">参照対象</h3>

                  <div>
                    <label className="flex items-center">
                      <select
                        className="dropdown-bar"
                        value={selectedOption}
                        onChange={(e) => {
                          handleDropdownChange(e);
                        }}
                      >
                        <option value="newest">最新</option>
                        {contactHistoryData?.map((item, index) => (
                          <option key={index} value={item.created_at}>
                            {convertToJapaneseTime(item.created_at)}
                          </option>
                        ))}
                      </select>
                    </label>
                  </div>

                  <div className="divider mt-5 mb-6"></div>

                  <div className="mt-10">
                    <h2 className="mb-5 label-light-blue-xl">
                      {" "}
                      氏名 <span className="text-red-500">*</span>{" "}
                    </h2>

                    <div className="flex flex-row">
                      <div>
                        <h2 className="mb-5 label-light-blue-md"> 姓 </h2>
                        <input
                          type="text"
                          className={
                            isViewingHistory
                              ? "form-text-field-border-gray"
                              : "form-text-field-border"
                          }
                          value={contactData?.last_name}
                          required
                          disabled={isViewingHistory}
                          onChange={(e) =>
                            handleNumericalCheck(
                              "last_name",
                              e.target.value,
                              updateUserData,
                              updateError,
                            )
                          }
                        />
                        {errors.last_name && (
                          <p className="text-red-500">
                            {UserFieldErrorMessages.nameError}
                          </p>
                        )}
                      </div>

                      <div>
                        <h2 className="mb-5 label-light-blue-md"> 名 </h2>
                        <input
                          type="text"
                          className={
                            isViewingHistory
                              ? "form-text-field-border-gray"
                              : "form-text-field-border"
                          }
                          value={contactData?.first_name}
                          disabled={isViewingHistory}
                          required
                          onChange={(e) =>
                            handleNumericalCheck(
                              "first_name",
                              e.target.value,
                              updateUserData,
                              updateError,
                            )
                          }
                        />
                        {errors.first_name && (
                          <p className="text-red-500">
                            {UserFieldErrorMessages.nameError}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="divider mt-5 mb-6"></div>

                    <h2 className="mb-5 label-light-blue-xl"> ふりがな{""}</h2>

                    <div className="flex flex-row">
                      <div>
                        <h2 className="mb-5 label-light-blue-md"> せい </h2>
                        <input
                          type="text"
                          className={
                            isViewingHistory
                              ? "form-text-field-border-gray"
                              : "form-text-field-border"
                          }
                          value={contactData?.last_furigana_name}
                          disabled={isViewingHistory}
                          // required
                          onChange={(e) => {
                            handleHiraganaKanaChange(
                              "last_furigana_name",
                              e.target.value,
                              updateUserData,
                              updateError,
                            );
                          }}
                        />
                        {errors.last_furigana_name && (
                          <p className="text-red-500">
                            {UserFieldErrorMessages.nameHiraganaError}
                          </p>
                        )}
                        {errors.kana_name_length && (
                          <p className="text-red-500">
                            {UserFieldErrorMessages.lengthError}
                          </p>
                        )}
                      </div>

                      <div>
                        <h2 className="mb-5 label-light-blue-md"> めい </h2>
                        <input
                          type="text"
                          className={
                            isViewingHistory
                              ? "form-text-field-border-gray"
                              : "form-text-field-border"
                          }
                          value={contactData?.first_furigana_name}
                          disabled={isViewingHistory}
                          onChange={(e) => {
                            handleHiraganaKanaChange(
                              "first_furigana_name",
                              e.target.value,
                              updateUserData,
                              updateError,
                            );
                          }}
                        />
                        {errors.first_furigana_name && (
                          <p className="text-red-500">
                            {UserFieldErrorMessages.nameHiraganaError}
                          </p>
                        )}
                        {errors.kana_name_length && (
                          <p className="text-red-500">
                            {UserFieldErrorMessages.lengthError}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="divider mt-5 mb-6"></div>

                    <h2 className="mb-5 label-light-blue-xl">
                      {" "}
                      メールアドレス
                    </h2>

                    <input
                      type="email"
                      className={
                        isViewingHistory
                          ? "form-text-field-border-gray"
                          : "form-text-field-border"
                      }
                      value={contactData?.email}
                      disabled={isViewingHistory}
                      onChange={(e) => {
                        updateUserData("email", e.target.value);
                      }}
                    />
                    {errors.email && (
                      <p className="text-red-500">
                        {UserFieldErrorMessages.telephoneNumberError}
                      </p>
                    )}
                    <div className="divider mt-5 mb-6"></div>

                    <h2 className="mb-5 label-light-blue-xl">
                      {" "}
                      電話番号 <span className="text-red-500">*</span>{" "}
                    </h2>

                    <input
                      type="tel"
                      className={
                        isViewingHistory
                          ? "form-text-field-border-gray"
                          : "form-text-field-border"
                      }
                      value={contactData?.personal_tel}
                      required
                      placeholder="08012345678"
                      disabled={isViewingHistory}
                      onChange={(e) => {
                        phoneNumberChange(
                          "personal_tel",
                          e.target.value,
                          updateUserData,
                          updateError,
                        );
                      }}
                    />
                    {errors.personal_tel && (
                      <p className="text-red-500">
                        {UserFieldErrorMessages.telephoneNumberError}
                      </p>
                    )}
                    <div className="divider mt-5 mb-6"></div>
                  </div>
                </div>
              }
            ></NewCard>

            <NewCard
              header={`企業情報 ${!isExternalUser ? " - 内部" : " - 外部"}`}
              bodyLabel={""}
              isLoading={loading}
              bodyContents={
                <div>
                  <h2 className="mb-5 label-light-blue-xl"> 会社名 </h2>
                  <input
                    type="text"
                    className={
                      isViewingHistory
                        ? "form-text-field-border-gray"
                        : "form-text-field-border"
                    }
                    value={contactData?.company_name}
                    readOnly={!isExternalUser}
                    disabled={isViewingHistory}
                    onChange={(e) =>
                      handleNumericalCheck(
                        "company_name",
                        e.target.value,
                        updateUserData,
                        updateError,
                      )
                    }
                  />
                  {errors.company_name && (
                    <p className="text-red-500">
                      {UserFieldErrorMessages.nameError}
                    </p>
                  )}

                  <div className="divider mt-5 mb-6"></div>

                  <h2 className="mb-5 label-light-blue-xl"> 部署・役職 </h2>
                  <input
                    type="s"
                    className={
                      isViewingHistory
                        ? "form-text-field-border-gray"
                        : "form-text-field-border"
                    }
                    value={contactData?.position_name}
                    readOnly={!isExternalUser}
                    disabled={isViewingHistory}
                    onChange={(e) =>
                      handleNumericalCheck(
                        "position_name",
                        e.target.value,
                        updateUserData,
                        updateError,
                      )
                    }
                  />
                  {errors.position_name && (
                    <p className="text-red-500">
                      {UserFieldErrorMessages.positionError}
                    </p>
                  )}

                  <div className="divider mt-5 mb-6"></div>

                  <h2 className="mb-5 label-light-blue-xl"> 会社電話番号 </h2>
                  <input
                    type="tel"
                    className={
                      isViewingHistory
                        ? "form-text-field-border-gray"
                        : "form-text-field-border"
                    }
                    value={contactData?.company_tel}
                    readOnly={!isExternalUser}
                    disabled={isViewingHistory}
                    placeholder="08012345678"
                    onChange={(e) =>
                      phoneNumberChange(
                        "company_tel",
                        e.target.value,
                        updateUserData,
                        updateError,
                      )
                    }
                  />
                  {errors.company_tel && (
                    <p className="text-red-500">
                      {UserFieldErrorMessages.telephoneNumberError}
                    </p>
                  )}
                  <div className="divider mt-5 mb-6"></div>

                  <h2 className="mb-5 label-light-blue-xl"> 会社FAX番号 </h2>
                  <input
                    type="tel"
                    className={
                      isViewingHistory
                        ? "form-text-field-border-gray"
                        : "form-text-field-border"
                    }
                    value={contactData?.company_fax}
                    readOnly={!isExternalUser}
                    disabled={isViewingHistory}
                    placeholder="08012345678"
                    onChange={(e) =>
                      phoneNumberChange(
                        "company_fax",
                        e.target.value,
                        updateUserData,
                        updateError,
                      )
                    }
                  />
                  {errors.company_fax && (
                    <p className="text-red-500">
                      {UserFieldErrorMessages.faxNumberError}
                    </p>
                  )}
                  <div className="divider mt-5 mb-6"></div>

                  <h2 className="mb-5 label-light-blue-xl">
                    {" "}
                    会社ホームページ{" "}
                  </h2>
                  <input
                    type="url"
                    className={
                      isViewingHistory
                        ? "form-text-field-border-gray"
                        : "form-text-field-border"
                    }
                    value={contactData?.company_home_page}
                    readOnly={!isExternalUser}
                    disabled={isViewingHistory}
                    onChange={(e) =>
                      updateUserData("company_home_page", e.target.value)
                    }
                  />

                  <div className="divider mt-5 mb-6"></div>
                </div>
              }
            ></NewCard>

            <NewCard
              header={"登録情報"}
              bodyLabel={""}
              isLoading={loading}
              bodyContents={
                <div>
                  <h2 className="mb-5 label-light-blue-xl"> 登録日 </h2>
                  <input
                    type="text"
                    className={"form-text-field-border-gray"}
                    value={
                      createContact
                        ? new Date().toLocaleString("ja-JP", {
                            timeZone: "Asia/Tokyo",
                          })
                        : contactData?.created_at?.split("T")[0]
                    }
                    required
                    readOnly
                    onChange={(e) => setDateCreated(e.target.value)}
                  />

                  {isViewingHistory && (
                    <>
                      <div className="divider mt-5 mb-6"></div>

                      <h2 className="mb-5 label-light-blue-xl"> 登録者 </h2>
                      <input
                        type="text"
                        className="form-text-field-border-gray"
                        value={
                          implementerLogin != "not found"
                            ? implementerLogin
                            : "削除されたユーザー"
                        }
                        required
                        readOnly
                      />
                    </>
                  )}
                </div>
              }
            ></NewCard>
            <WarningModal
              title={messages.generic.error_modal_title}
              body={error}
              confirmText={""}
              cancelText="戻る"
              isOpen={warningModalOpen}
              onRequestClose={handleCloseWarningModal}
            ></WarningModal>
            {!isViewingHistory && (
              <ConfirmCancelButtons
                confirmText={"更新する"}
                form={true}
                onCancel={onCancel}
              />
            )}
          </form>
        )}
      </div>
    </>
  );
};

export default ContactProfileForm;
