import React, { useEffect, useState } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";

import TenantLicenseTable from "../../components/Tables/TenantLicenseTable";

import { NewCard } from "../../components/Cards/Templates/newCard";
import { ConfirmCancelButtons } from "../../components/Buttons/ConfirmCancelButtons";
import { TENANT } from "../../types/tenant";
import {
  getCompanyContractData,
  getTenantData,
  putContractData,
  putTenantData,
} from "../../services/API/Requests";
import { toast } from "react-toastify";
import LoadingIcon from "../../common/LoadingIcon";
import {
  handleHiraganaKanaChange,
  handleNumericalFieldChange,
  handlePostCodeChange,
  handleSubDirectoryChange,
  phoneNumberChange,
  TranslateErrorMessage,
} from "../../services/ErrorMessages";
import { useSelector } from "react-redux";
import CreateTenantLicenseTable from "../../components/Tables/CreateTenantLicenseTable";
import { CONTRACT } from "../../types/contract";
import NotFound from "../../components/ErrorPages/PageNotFound";
import { CONTRACT_STATUS } from "../../constants/Enums";
import {
  error_message,
  TenantFieldErrorMessages,
} from "../../constants/Errors";
import WarningModal from "../../components/Modals/WarningModal";
import messages from "../../constants/Messages";
import { BlankTenantData } from "../../templates/BlankTenantData";
import { handleResponse } from "../../services/API/StatusCodes";

interface TenantInformationProps {
  tenantCode: string | undefined;
}

const ViewTenantForm: React.FC<TenantInformationProps> = ({ tenantCode }) => {
  const isAdminLoggedIn: boolean = useSelector((state) => state.auth.isAdmin);

  if (tenantCode !== localStorage.getItem("realm") && !isAdminLoggedIn) {
    return <NotFound></NotFound>;
  }

  const navigate: NavigateFunction = useNavigate();

  const id: string | null = tenantCode || localStorage.getItem("realm");
  const [tenantData, setTenantData] = useState<TENANT | undefined>(
    BlankTenantData,
  );
  const [loading, setLoading] = useState<boolean>(true);

  const [error, setError] = useState<string>("");

  const [contractData, setContractData] = useState<CONTRACT[]>([]);

  const errorToast = (response) => toast.error(`${response}`);
  const successToast = (response) => toast.success(`${response}`);

  const [dateError, setDateError] = useState<boolean>(false);
  const [unitPriceError, setUnitPriceError] = useState<boolean>(false);

  const [warningModalOpen, setWarningModalOpen] = useState<boolean>(false);

  const [errors, setErrors] = useState({
    name_kana: false,
    name_english: false,
    admin_kana_name: false,
    postal_code: false,
    address: false,
    phone_number: false,
    fax_number: false,
    sub_directory: false,
    store_id: false,
    shoppers_cloud_url: false,
  });

  const verifyFields = () => {
    return !Object.values(errors).some((error) => error === true);
  };

  useEffect(() => {
    if (id) {
      const fetchTenantData = async () => {
        setLoading(true);
        try {
          await getTenantData(setTenantData, setLoading, setError, id);
        } catch (error) {
          setError(error);
        } finally {
          setLoading(false);
        }
      };

      fetchTenantData();
    }
  }, [id]);

  useEffect(() => {
    const fetchLicenseData = async () => {
      setLoading(true);
      try {
        await getCompanyContractData(setContractData, setLoading, setError, id);
      } catch (error) {
        setError(error.message || "");
      } finally {
        setLoading(false);
      }
    };

    fetchLicenseData();
  }, [id]);

  const updateContractData = (
    app_id: number,
    key: keyof CONTRACT,
    value: any,
  ) => {
    setContractData((prevState) =>
      prevState.map((contract) =>
        contract.app_id === app_id ? { ...contract, [key]: value } : contract,
      ),
    );
  };

  const updateTenantData = (key: keyof TENANT, value: string | boolean) => {
    setTenantData((prevState) => {
      if (prevState) {
        return {
          ...prevState,
          [key]: value,
        };
      }
      return prevState;
    });
  };

  const validateDates = () => {
    let hasError: boolean = false;

    const emptyContentError: string = error_message.tenant.date_not_defined;
    const illegalDatesError: string = error_message.tenant.bad_input;

    contractData.forEach((contract) => {
      if (contract.status === CONTRACT_STATUS.ACTIVE) {
        if (!contract.start_date) {
          hasError = true;
          errorToast(emptyContentError);
        }

        if (
          contract.start_date &&
          contract.end_date &&
          new Date(contract.start_date) > new Date(contract.end_date)
        ) {
          hasError = true;
          errorToast(illegalDatesError);
        }
      }
    });

    setDateError(hasError);
    return hasError;
  };

  const validateUnitPrice = () => {
    const regex: RegExp = /^[0-9]*$/;
    let hasError: boolean = false;

    const emptyContentError: string = error_message.tenant.blank_unit_price;
    const illegalCharactersError: string = error_message.tenant.bad_unit_price;

    contractData.forEach((contract) => {
      if (contract.status === CONTRACT_STATUS.ACTIVE) {
        const unitPriceString: string = contract.unit_price.toString();
        if (unitPriceString === "0") {
          hasError = true;
          errorToast(emptyContentError);
        }
        if (!regex.test(unitPriceString)) {
          hasError = true;
          errorToast(illegalCharactersError);
        }
      }
    });

    setUnitPriceError(hasError);
    return hasError;
  };

  const onCancel = () => {
    navigate(!isAdminLoggedIn ? "/home" : "/company/manage");
  };

  const onSubmit = (event) => {
    setError("");
    event.preventDefault();
    const validDates: boolean = validateDates();

    if (!verifyFields()) {
      errorToast(error_message.generic.bad_field);
      return;
    }

    if (validDates) {
      return;
    }

    if (!validateUnitPrice) {
      return;
    }

    putTenantData(tenantData, setLoading, setError)
      .then((response) => {
        if (response.status == 200) {
          if (isAdminLoggedIn) {
            putContractData(
              contractData,
              setLoading,
              setError,
              tenantCode,
            ).then((response) => {
              if (response.status == 200) {
                successToast(handleResponse(response, "テナント").message);
              } else {
                const errorMessage: string = TranslateErrorMessage(response);
                console.error(errorMessage);
                setError(errorMessage);
                errorToast(errorMessage);
              }
            });
          } else {
            successToast(response.message);
          }
        } else {
          const errorMessage: string = TranslateErrorMessage(response.message);
          console.error(errorMessage);
          setError(errorMessage);
          errorToast(errorMessage);
        }
      })
      .catch((error) => {
        const errorMessage: string = TranslateErrorMessage(error.message);
        console.error(errorMessage);
        setError(errorMessage);
        errorToast(errorMessage);
      });
  };

  const updateError = (field, value) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: value,
    }));
  };

  const handleOpenWarningModal = () => {
    setWarningModalOpen(true);
  };

  const handleCloseWarningModal = () => {
    setWarningModalOpen(false);
  };

  return (
    <div>
      {loading ? (
        <LoadingIcon></LoadingIcon>
      ) : tenantData?.id ? (
        <>
          <div className="flex flex-row justify-end">
            {error && (
              <button
                className="button-default"
                type="button"
                onClick={handleOpenWarningModal}
              >
                {messages.generic.show_error}
              </button>
            )}
          </div>
          <form
            onSubmit={onSubmit}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
          >
            <NewCard
              header={"契約状態"}
              bodyLabel={""}
              isTable={true}
              isLoading={loading}
              bodyContents={
                <div className="mayo-card-body">
                  {isAdminLoggedIn ? (
                    <CreateTenantLicenseTable
                      contractData={contractData}
                      setContractData={setContractData}
                      updateContractData={updateContractData}
                    ></CreateTenantLicenseTable>
                  ) : (
                    <TenantLicenseTable id={id} />
                  )}
                </div>
              }
            />

            <NewCard
              header={"基本情報"}
              bodyLabel={""}
              isLoading={loading}
              bodyContents={
                <div>
                  <h2 className="mb-6 label-light-blue-xl">
                    企業名 <span className="text-red-500">*</span>
                  </h2>
                  <input
                    type="text"
                    required
                    className="form-text-field-border"
                    value={tenantData.name}
                    onChange={(e) =>
                      updateTenantData("name", e.target.value.trim())
                    }
                  />
                  <div className="mt-5 divider"></div>

                  <h2 className="mb-6 label-light-blue-xl">
                    企業名ふりがな <span className="text-red-500">*</span>
                  </h2>
                  <input
                    type="text"
                    required
                    className="form-text-field-border"
                    value={tenantData?.name_kana}
                    onChange={(e) => {
                      handleHiraganaKanaChange(
                        "name_kana",
                        e.target.value,
                        updateTenantData,
                        updateError,
                      );
                    }}
                  />
                  {errors.name_kana && (
                    <p className="text-red-500">
                      {TenantFieldErrorMessages.nameKanaError}
                    </p>
                  )}
                  <div className="mt-5 divider"></div>

                  <h2 className="mb-6 label-light-blue-xl">
                    テナントID <span className="text-red-500">*</span>
                  </h2>
                  <input
                    type="text"
                    required
                    className="form-text-field-border-gray"
                    disabled
                    value={tenantData?.code}
                  />
                  <div className="mt-5 divider"></div>

                  <h2 className="mb-6 label-light-blue-xl">
                    郵便番号<span className="text-red-500">* </span>
                    （ハイフンなし）
                  </h2>
                  <input
                    type="text"
                    required
                    placeholder="1234567"
                    className="form-text-field-border"
                    value={tenantData?.postal_code}
                    onChange={(e) => {
                      handlePostCodeChange(
                        "postal_code",
                        e.target.value,
                        updateTenantData,
                        updateError,
                      );
                    }}
                  />
                  {errors.postal_code && (
                    <p className="text-red-500">
                      {TenantFieldErrorMessages.postcodeError}
                    </p>
                  )}
                  <div className="mt-5 divider"></div>

                  <h2 className="mb-6 label-light-blue-xl">
                    住所 <span className="text-red-500">*</span>
                  </h2>
                  <input
                    type="text"
                    required
                    className="form-text-field-border"
                    value={tenantData.address}
                    onChange={(e) =>
                      updateTenantData("address", e.target.value.trim())
                    }
                  />
                  <div className="mt-5 divider"></div>

                  <h2 className="mb-6 label-light-blue-xl">
                    電話番号 <span className="text-red-500">*</span>
                  </h2>
                  <input
                    type="text"
                    required
                    placeholder="08012345678"
                    className="form-text-field-border"
                    value={tenantData?.phone_number}
                    onChange={(e) => {
                      phoneNumberChange(
                        "phone_number",
                        e.target.value,
                        updateTenantData,
                        updateError,
                      );
                    }}
                  />
                  {errors.phone_number && (
                    <p className="text-red-500">
                      {TenantFieldErrorMessages.telephoneNumberError}
                    </p>
                  )}
                  <div className="mt-5 divider"></div>

                  <h2 className="mb-6 label-light-blue-xl">FAX番号</h2>
                  <input
                    type="text"
                    placeholder="08012345678"
                    className="form-text-field-border"
                    value={tenantData?.fax_number}
                    onChange={(e) => {
                      phoneNumberChange(
                        "fax_number",
                        e.target.value,
                        updateTenantData,
                        updateError,
                      );
                    }}
                  />
                  {errors.fax_number && (
                    <p className="text-red-500">
                      {TenantFieldErrorMessages.faxNumberError}
                    </p>
                  )}
                  <div className="mt-5 divider"></div>

                  <h2 className="mb-6 label-light-blue-xl">企業URL</h2>
                  <input
                    type="text"
                    className="form-text-field-border"
                    value={tenantData.url}
                    onChange={(e) =>
                      updateTenantData("url", e.target.value.trim())
                    }
                  />
                  <div className="mt-5 divider"></div>
                </div>
              }
            />
            
            <NewCard
              header={"ShoppersCloudの設定"}
              bodyLabel={""}
              isLoading={loading}
              bodyContents={
                <div>
                  <h2 className="mb-6 label-light-blue-xl">URL</h2>
                  <div className="flex ml-5 mb-2 text-xl text-black">
                    <input
                      type="url"
                      className="form-text-field-border"
                      value={tenantData?.shoppers_cloud_url}
                      onChange={(e) =>
                        updateTenantData("shoppers_cloud_url", e.target.value)
                      }
                    />
                  </div>

                  <div className="mt-5 divider"></div>

                  <h2 className="mt-6 mb-6 label-light-blue-xl">
                    Sub-Directory
                  </h2>
                  <div className="flex ml-5 mb-2 text-xl text-black">
                    <input
                      type="text"
                      className="form-text-field-border"
                      value={tenantData?.sub_directory}
                      onChange={(e) =>
                        handleSubDirectoryChange(
                          "sub_directory",
                          e.target.value,
                          updateTenantData,
                          updateError,
                        )
                      }
                    />
                  </div>
                  {errors.sub_directory && (
                    <p className="text-red-500">
                      {TenantFieldErrorMessages.subDirectoryError}
                    </p>
                  )}

                  <div className="mt-5 divider"></div>

                  <h2 className="mt-6 mb-6 label-light-blue-xl">店舗ID</h2>
                  <div className="flex ml-5 mb-2 text-xl text-black">
                    <input
                      type="text"
                      className="form-text-field-border"
                      value={tenantData?.store_id}
                      onChange={(e) =>
                        handleNumericalFieldChange(
                          "store_id",
                          e.target.value,
                          updateTenantData,
                          updateError,
                        )
                      }
                    />
                  </div>
                  {errors.store_id && (
                    <p className="text-red-500">
                      {TenantFieldErrorMessages.storeIdError}
                    </p>
                  )}
                </div>
              }
            />

            <NewCard
              header={"セキュリティ設定"}
              bodyLabel={""}
              isLoading={loading}
              bodyContents={
                <div>
                  <h2 className="mb-6 label-light-blue-xl">ログイン方法設定</h2>
                  <div className="flex ml-5 mb-2 text-xl text-black">
                    <label className="flex items-center mr-5">
                      <input
                        type="checkbox"
                        className="mr-5 w-5 h-5"
                        checked={tenantData?.local_auth}
                        disabled={isAdminLoggedIn}
                        onChange={() =>
                          updateTenantData(
                            "local_auth",
                            !tenantData?.local_auth,
                          )
                        }
                      />
                      ローカル認証
                    </label>
                  </div>

                  <div className="mt-5 divider"></div>

                  <h2 className="mt-6 mb-6 label-light-blue-xl">
                    電話帳参照制限
                  </h2>
                  <div className="ml-5 mb-2 text-xl text-black">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        className="mr-5 w-5 h-5"
                        checked={tenantData?.require_passcode_for_phonebook}
                        disabled={isAdminLoggedIn}
                        onChange={() =>
                          updateTenantData(
                            "require_passcode_for_phonebook",
                            !tenantData?.require_passcode_for_phonebook,
                          )
                        }
                      />
                      電話帳参照時にパスコードの入力を求める
                    </label>
                  </div>
                </div>
              }
            />

            <ConfirmCancelButtons
              confirmText={"更新する"}
              form={true}
              onCancel={onCancel}
            />
          </form>
          <WarningModal
            title={messages.generic.error_modal_title}
            body={error}
            confirmText={""}
            cancelText="戻る"
            isOpen={warningModalOpen}
            onRequestClose={handleCloseWarningModal}
          ></WarningModal>
        </>
      ) : (
        <p className="not-found-text">{`テナント: ${id}のデータが見つかりませんでした`}</p>
      )}
    </div>
  );
};

export default ViewTenantForm;
