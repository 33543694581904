import React from "react";
import { TitleWithDivider } from "../../common/TitleWithDivider.tsx";
import ContactProfileForm from "../../components/Forms/ContactProfileForm";
import pageHeaders from "../../constants/PageHeaders.tsx";

const ViewContactPage: React.FC = () => {
  return (
    <>
      <div>
        <TitleWithDivider
          titleText={pageHeaders.KAKELY.phonebookEdit}
          useDivider={true}
        />
        <ContactProfileForm></ContactProfileForm>
      </div>
    </>
  );
};

export default ViewContactPage;
