export const userSearchFilters = {
  last_name: "氏名（姓）",
  first_name: "氏名（名）",
  last_furigana_name: "かな（せい）",
  first_furigana_name: "かな（めい）",
  department: "部署",
  company_name: "企業名",
  company_fax: "企業FAX番号",
  email: "メールアドレス",
  personal_tel: "電話番号",
  app_name: "利用可能アプリ",
};

export const tenantSearchFilters = {
  name: "企業名",
  postal_code: "郵便番号",
  name_kana: "かな名",
  company_name: "企業名",
  code: "テナントID",
  address: "住所",
  phone_number: "電話番号",
  app_name: "利用可能アプリ",
  url: "URL",
};
