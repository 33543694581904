import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import EditiableNotificationCard from "../../components/Cards/Notification/EditNotificationCard";
import ViewNotificationCard from "../../components/Cards/Notification/ViewNotificationCard";
import PauseModal from "../../components/Modals/WarningModal";
import StartModal from "../../components/Modals/WarningModal";
import { TitleWithDivider } from "../../common/TitleWithDivider";
import {
  getNotificationData,
  putNotificationData,
} from "../../services/API/Requests";
import { NOTIFICATION } from "../../types/notification";
import LoadingIcon from "../../common/LoadingIcon";
import { TranslateErrorMessage } from "../../services/ErrorMessages";
import { useSelector } from "react-redux";
import messages from "../../constants/Messages";
import { error_message } from "../../constants/Errors";
import { NOTIFICATION_PATHS } from "../../constants/NavigationPaths";
import pageHeaders from "../../constants/PageHeaders";

const ViewNotificationPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const broadcastToast = () => toast.success(messages.notification.restart);
  const stopToast = () => toast.success(messages.notification.stop);

  // Start and pause modal variables
  const startModalTitle: string = "再配信注意";
  const startModalBody: string = "お知らせを再配信します。よろしいですか？";
  const startModalConfirm: string = "再配信する";

  const pauseModalTitle: string = "停止注意";
  const pauseModalBody: string = "お知らせを停止します。よろしいですか？";
  const pauseModalConfirm: string = "停止する";

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [notification, setNotification] = useState<NOTIFICATION | undefined>();
  const [pauseModalOpen, setPauseModalOpen] = useState<boolean>(false);
  const [startModalOpen, setStartModalOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(true);

  const errorToast = (response) => toast.error(`${response}`);
  const successToast = (response) => toast.success(`${response}`);

  const isAdminLoggedIn: boolean = useSelector((state) => state.auth.isAdmin);

  const { viewOnly } = location.state || !isAdminLoggedIn;

  useEffect(() => {
    setLoading(true);
    getNotificationData(setNotification, setLoading, setError, id);
  }, [id]);

  const handleStopClick = () => {
    setPauseModalOpen(true);
  };

  const handleStopCloseModal = () => {
    setPauseModalOpen(false);
  };

  const handleStopConfirm = () => {
    setPauseModalOpen(false);
    stopToast();
    setStatus(false);
    if (notification) {
      notification.status = !notification?.status;
    } else {
      console.error(error_message.notification.is_null);
    }
  };

  const handleCloseStartModal = () => {
    setStartModalOpen(false);
  };

  const handleStartClick = () => {
    setStartModalOpen(true);
  };

  const handleStartConfirm = () => {
    setStartModalOpen(false);
    broadcastToast();
    setStatus(true);
    if (notification) {
      notification.status = !notification.status;
    } else {
      console.error(error_message.notification.is_null);
    }
  };

  const toggleEditMode = () => {
    setIsEditing((prev) => !prev);
  };

  const handleCancelButtonClick = () => {
    if (isEditing) {
      navigate(NOTIFICATION_PATHS.main);
    } else {
      toggleEditMode();
    }
  };

  const validateDates = () => {
    const startDate: Date = new Date(notification?.start_date);
    const endDate: Date = new Date(notification?.end_date);

    if (startDate > endDate) {
      errorToast(error_message.notification.start_data);
      return false;
    } else {
      return true;
    }
  };

  const handleUpdateButtonClick = () => {
    if (!isEditing) {
      let response;

      const validDates: boolean = validateDates();

      if (!validDates) {
        return;
      }

      putNotificationData(notification, setLoading, setError, notification?.id)
        .then((response) => {
          if (response.status == 200) {
            console.log("succesfully updated notification");
            successToast(response.message);
          } else {
            const errorMessage = TranslateErrorMessage(response.message);
            console.error(errorMessage);
            setError(errorMessage);
            errorToast(errorMessage);
          }
        })
        .catch((error) => {
          const errorMessage = TranslateErrorMessage(response.message);
          console.error(errorMessage);
          setError(errorMessage);
          errorToast(errorMessage);
        });
      setTimeout(() => {
        navigate(NOTIFICATION_PATHS.main);
      }, 3000);
    } else {
      toggleEditMode();
    }
  };

  if (loading) {
    return (
      <div>
        <LoadingIcon></LoadingIcon>
      </div>
    );
  }

  if (notification?.status == false && !isAdminLoggedIn) {
    return (
      <div>
        <p className="not-found-text">
          {error_message.notification.not_found(id)}
        </p>
      </div>
    );
  }

  return (
    <>
      <TitleWithDivider
        titleText={pageHeaders.NOTIFICATION.view}
        useDivider={true}
      />
      {notification ? (
        <div className="md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
          {!viewOnly && isAdminLoggedIn ? (
            <EditiableNotificationCard
              notificationData={notification}
              setNotificationData={setNotification}
              isEditing={isEditing}
              viewOnly={viewOnly}
            />
          ) : (
            <ViewNotificationCard notificationData={notification} />
          )}

          {!viewOnly && isAdminLoggedIn && (
            <div className="flex justify-between items-center gap-2.5 mt-8">
              <div
                className="inline-flex items-center justify-center gap-2.5 delete-button-frame"
                onClick={
                  notification?.status === false
                    ? handleStartClick
                    : handleStopClick
                }
              >
                {notification?.status === false ? "再配信" : "配信停止"}
              </div>
              <div className="flex justify-end">
                <div
                  className="button-cancel"
                  onClick={handleCancelButtonClick}
                >
                  キャンセル
                </div>
                <div
                  className="button-confirm"
                  onClick={handleUpdateButtonClick}
                >
                  {isEditing === false ? "更新する" : "確認"}
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <p className="not-found-text">
          {error_message.notification.not_found(id)}
        </p>
      )}

      <PauseModal
        isOpen={pauseModalOpen}
        onRequestClose={handleStopCloseModal}
        onConfirm={handleStopConfirm}
        title={pauseModalTitle}
        body={pauseModalBody}
        confirmText={pauseModalConfirm}
        cancelText={""}
      />

      <StartModal
        isOpen={startModalOpen}
        onRequestClose={handleCloseStartModal}
        onConfirm={handleStartConfirm}
        title={startModalTitle}
        body={startModalBody}
        confirmText={startModalConfirm}
        cancelText={""}
      />
    </>
  );
};

export default ViewNotificationPage;
