import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { USER } from "../../types/user";
import BlankUser from "../../templates/BlankUserData";
import { BlankTenantDepartmentData } from "../../templates/BlankTenantDepartmentData";
import { BlankUserTenantData } from "../../templates/BlankUserTenantData";
import { TENANTDEPARTMENT } from "../../types/tenantDepartment";
import { USERTENANT } from "../../types/usertenant";
import { APP } from "../../types/app";
import { BlankRoleData } from "../../templates/BlankRoleData";
import { ROLE } from "../../types/role";

const initialState = {
  userData: BlankUser,
  tempUserId: "",
  tenantDepartmentData: BlankTenantDepartmentData,
  userTenantData: BlankUserTenantData,
  selectedAppsData: [] as APP[],
  selectedRoleData: BlankRoleData,
  imagePreviewData: [],
  filesData: [] as File[],
};

const userFormSlice = createSlice({
  name: "userForm",
  initialState,
  reducers: {
    setTempUserId(state, action: PayloadAction<string>) {
      state.tempUserId = action.payload;
    },
    setUserFormData(state, action: PayloadAction<USER>) {
      state.userData = action.payload;
    },
    setTenantDepartmentFormData(
      state,
      action: PayloadAction<TENANTDEPARTMENT>,
    ) {
      state.tenantDepartmentData = action.payload;
    },
    setUserTenantDepartmentData(state, action: PayloadAction<USERTENANT>) {
      state.userTenantData = action.payload;
    },
    setSelectedAppsData(state, action: PayloadAction<[APP]>) {
      state.selectedAppsData = action.payload;
    },
    setSelectedRoleData(state, action: PayloadAction<ROLE>) {
      state.selectedRoleData = action.payload;
    },
    setImagePreviewData(state, action: PayloadAction<[]>) {
      state.imagePreviewData = action.payload;
    },
    setFilesData(state, action: PayloadAction<File[]>) {
      state.filesData = action.payload;
    },
    clearUserFormData(state) {
      return {
        ...initialState,
        filesData: state.filesData,
      };
    },
    clearUserFormImageData() {
      return { ...initialState };
    },
  },
});

export const {
  setUserFormData,
  clearUserFormData,
  clearUserFormImageData,
  setTempUserId,
  setTenantDepartmentFormData,
  setUserTenantDepartmentData,
  setSelectedAppsData,
  setSelectedRoleData,
  setImagePreviewData,
  setFilesData,
} = userFormSlice.actions;
export default userFormSlice.reducer;
