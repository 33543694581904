const pageHeaders = {
  USER: {
    main: "ユーザー一覧",
    edit: "ユーザー情報編集",
    create: "ユーザー情報参照・編集",
    add: "ユーザー新規追加",
    photoUpload: "顔写真アップロード",
    changePassword: "パスワード変更",
    myPage: "マイページ",
    personalInfo: "個人情報",
    passwordChange: "パスワード変更",
    csvUpload: "ユーザーCSVアップロード",
    csvUploadTitle: "ユーザーCSVアップロード",
  },
  TENANT: {
    main: "企業一覧",
    manage: "企業情報管理",
    create: "企業情報作成",
    add: "企業新規追加",
    edit: "企業情報設定",
    csvUpload: "企業CSVアップロード",
    csvUploadTitle: "企業CSVアップロード",
  },
  NOTIFICATION: {
    main: "お知らせ一覧",
    create: "お知らせ追加",
    add: "お知らせ追加",
    edit: "お知らせ編集",
    view: "お知らせ登録・編集",
  },
  KAKELY: {
    main: "社外電話帳一覧",
    departmentView: "部署一覧表示設定",
    departmentSettings: "部署一覧表示設定",
    departmentCreate: "部署一覧表示設定",
    departmentMain: "部署一覧",
    departmentEdit: "部署情報更新",
    departmentAdd: "新部署追加",
    departmentMasterAdd: "新マスタ追加",
    phonebookEdit: "電話帳登録・編集画面",
    phonebookAdd: "電話帳登録・編集画面",
    phonebookView: "電話帳情報",
    csvUpload: "電話帳CSVアップロード",
    csvUploadTitle: "電話帳CSVアップロード",
  },
  YOBELY: {
    main: "YOBELY",
    groupView: "一斉発信グループ一覧・登録",
    groupCreate: "一斉発信グループ登録",
  },
  HOME: {
    main: "管理者からのお知らせ",
  },
};

export default pageHeaders;
