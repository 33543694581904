import React, { ChangeEvent, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TitleWithDivider } from "../../common/TitleWithDivider";
import { ConfirmCancelButtons } from "../../components/Buttons/ConfirmCancelButtons";
import { uploadUserImage } from "../../services/API/Requests";
import { error_message } from "../../constants/Errors";
import messages from "../../constants/Messages";
import pageHeaders from "../../constants/PageHeaders";

const UserPhotoUploadPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { users } = location.state || { users: [] };

  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  const [fileMap, setFileMap] = useState<{ [key: number]: File[] }>({});
  const [previewsMap, setPreviewsMap] = useState<{ [key: number]: string[] }>(
    {},
  );

  const fileInputs = useRef<{ [key: number]: HTMLInputElement | null }>({});

  const uploadImageDataToast = () =>
    toast.success(messages.image.upload_success);

  const uploadImageDataFailToast = () =>
    toast.error(error_message.image.upload);

  const handleUpload = () => {
    const uploadPromises = selectedUsers.map((userId) => {
      if (fileMap[userId]) {
        return uploadUserImage(userId, fileMap[userId])
          .then((response) => {
            if (response.status < 300) {
              uploadImageDataToast();
            } else {
              uploadImageDataFailToast();
            }
          })
          .catch((error) => {
            console.error(error_message.image.upload, error);
            uploadImageDataFailToast();
          });
      }
      return Promise.resolve();
    });
  };

  const handleUserSelection = (userId: number) => {
    setSelectedUsers((prevSelectedUsers) => {
      if (prevSelectedUsers.includes(userId)) {
        return prevSelectedUsers.filter((id) => id !== userId);
      } else {
        return [...prevSelectedUsers, userId];
      }
    });
  };

  const handleFileChange = (
    userId: number,
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const selectedFiles = Array.from(event.target.files || []);
    setFileMap((prevFileMap) => ({
      ...prevFileMap,
      [userId]: selectedFiles,
    }));

    const filePreviews = selectedFiles.map((file) => URL.createObjectURL(file));
    setPreviewsMap((prevPreviewsMap) => ({
      ...prevPreviewsMap,
      [userId]: filePreviews,
    }));
  };

  const handleClearFile = (userId: number) => {
    if (fileInputs.current[userId]) {
      fileInputs.current[userId].value = "";
    }

    setFileMap((prevFileMap) => {
      const newFileMap = { ...prevFileMap };
      delete newFileMap[userId];
      return newFileMap;
    });

    setPreviewsMap((prevPreviewsMap) => {
      const newPreviewsMap = { ...prevPreviewsMap };
      delete newPreviewsMap[userId];
      return newPreviewsMap;
    });
  };

  const onCancel = () => {
    navigate("/users");
  };

  return (
    <div>
      <TitleWithDivider
        titleText={pageHeaders.USER.photoUpload}
        useDivider={true}
      />

      <div className="bg-card flex flex-col p-5 bg-white rounded-lg border border-divider relative">
        {users.length > 0 ? (
          users?.map((user) => (
            <div key={user.id} className="mb-4">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={selectedUsers.includes(user.id)}
                  onChange={() => handleUserSelection(user.id)}
                />
                <span className="ml-2 text-xl font-bold text-font-label-blue">
                  {user.name}
                </span>
              </label>

              {selectedUsers.includes(user.id) && (
                <div className="mt-4 flex gap-4 items-center">
                  <button
                    className="p-2 button-confirm text-white rounded-xl min-w-40 max-w-40"
                    onClick={() => fileInputs.current[user.id]?.click()}
                  >
                    ファイルを選択
                  </button>

                  {fileMap[user.id]?.[0]?.name && (
                    <button
                      className="p-2 button-cancel text-white rounded-xl min-w-40 max-w-40"
                      onClick={() => handleClearFile(user.id)}
                    >
                      削除
                    </button>
                  )}
                  <input
                    type="file"
                    onChange={(e) => handleFileChange(user.id, e)}
                    accept=".png,.jpg,.jpeg"
                    ref={(el) => (fileInputs.current[user.id] = el)}
                    style={{ display: "none" }}
                  />
                  <div className="p-2 bg-white rounded-xl font-bold">
                    {fileMap[user.id]?.[0]?.name == null &&
                      error_message.image.not_selected}
                  </div>
                  <ul className="flex">
                    {previewsMap[user.id]?.map((preview, index) => (
                      <li key={index} className="text-center text-gray-700">
                        <img
                          src={preview}
                          alt={`preview ${index}`}
                          className="w-40 h-40 object-cover border rounded-full"
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))
        ) : (
          <p>{error_message.image.no_user}</p>
        )}
      </div>

      <p className="ml-5 mt-2 text-md text-red-400">
        {messages.image.icon_hint}
      </p>

      {users.length > 0 ? (
        <ConfirmCancelButtons
          confirmText={"インポート"}
          onCancel={onCancel}
          onConfirm={handleUpload}
        />
      ) : (
        <div className="flex justify-end mt-5">
          <button className="button-cancel" onClick={onCancel}>
            キャンセル
          </button>
        </div>
      )}
    </div>
  );
};

export default UserPhotoUploadPage;
