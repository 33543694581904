import React from "react";
import TableNotificationInfo from "../../components/Tables/NotificationTable";
import { TitleWithDivider } from "../../common/TitleWithDivider";
import pageHeaders from "../../constants/PageHeaders";

const HomeMainPage: React.FC = () => {
  return (
    <>
      <div>
        <TitleWithDivider titleText={pageHeaders.HOME.main} useDivider={true} />
        <TableNotificationInfo />
      </div>
    </>
  );
};
export default HomeMainPage;
