import React from "react";
import NotificationTable from "../../components/Tables/NotificationTable";
import { TitleWithDivider } from "../../common/TitleWithDivider";
import pageHeaders from "../../constants/PageHeaders";

const NotificationMainPage: React.FC = () => {
  return (
    <>
      <div>
        <div>
          <TitleWithDivider
            titleText={pageHeaders.NOTIFICATION.main}
            useDivider={true}
          />
          <NotificationTable />
        </div>
      </div>
    </>
  );
};

export default NotificationMainPage;
