import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useNavigate } from "react-router-dom";
import { TitleWithDivider } from "../../common/TitleWithDivider";
import { ConfirmCancelButtons } from "../../components/Buttons/ConfirmCancelButtons";
import PasswordForm from "../../components/Forms/PasswordForm";
import {
  resetUserPassword,
  validateUserExists,
} from "../../services/API/Requests";
import { USER } from "../../types/user";
import { TranslateErrorMessage } from "../../services/ErrorMessages";
import { GetPasswordPolicy } from "../../services/Utilities";
import { error_message, UserFieldErrorMessages } from "../../constants/Errors";
import messages from "../../constants/Messages";
import { GENERIC_PATHS } from "../../constants/NavigationPaths";
import LoadingIcon from "../../common/LoadingIcon";
import pageHeaders from "../../constants/PageHeaders";

const PasswordResetPage = ({}) => {
  const navigate = useNavigate();

  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const [userData, setUserData] = useState<USER | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const myUserPage: boolean = true;
  const id: string = "none";

  const passwordPolicy: string = GetPasswordPolicy();

  const noMatchToast = () => toast.error(error_message.password.no_match);
  const blankFieldToast = () => toast.error(error_message.password.blank_field);
  const errorToast = (error) => toast.error(error);
  const passwordChangedToast = () => toast.success(messages.password.success);

  const [errors, setErrors] = useState({
    password: false,
    verifyPassword: false,
    noMatch: false,
  });

  // function to verify if any of the fields contain errors
  const verifyFields = () => {
    return !Object.values(errors).some((error) => error === true);
  };

  // generic function to update error data based on key and value
  const updateError = (field, value) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: value,
    }));
  };

  useEffect(() => {
    const fetchUserData = async () => {
      await validateUserExists(
        setUserData,
        setLoading,
        setError,
        id, // no id as we use the token
        myUserPage, // myUserPage = true
      );
    };

    fetchUserData();
  }, []);

  const verifyBlankFields = () => {
    return newPassword !== "" && confirmPassword !== "";
  };

  const verifyNewPassword = () => {
    return newPassword === confirmPassword;
  };

  const verifyPasswords = (): boolean => {
    if (!verifyBlankFields()) {
      blankFieldToast();
      return false;
    }
    if (!verifyNewPassword()) {
      noMatchToast();
      return false;
    }

    if (!verifyFields()) {
      errorToast(error_message.generic.bad_field);
      return false;
    }

    return true;
  };

  const onCancel = () => {
    navigate(GENERIC_PATHS.home);
  };

  useEffect(() => {
    if (newPassword !== confirmPassword) {
      updateError("noMatch", true);
    } else {
      updateError("noMatch", false);
    }
  }, [newPassword, confirmPassword]);

  const onConfirm = async () => {
    let response;

    if (verifyPasswords()) {
      await resetUserPassword(confirmPassword, setLoading, setError)
        .then((response) => {
          if (response.status == 200) {
            passwordChangedToast();
          } else {
            const errorMessage = TranslateErrorMessage(response.message);
            console.error(errorMessage);
            setError(errorMessage);
            errorToast(errorMessage);
          }
        })
        .catch((error) => {
          const errorMessage = TranslateErrorMessage(response.message);
          console.error(errorMessage);
          setError(errorMessage);
          errorToast(errorMessage);
        });
      setLoading(false);
    }
  };

  return (
    <div>
      <TitleWithDivider
        titleText={pageHeaders.USER.passwordChange}
        useDivider={true}
      />

      {loading ? (
        <LoadingIcon />
      ) : userData ? (
        <div className="mayo-card-body rounded-xl border border-mayo-light-gray">
          <div className="mb-5">
            <PasswordForm
              identifier="password"
              label="変更後のパスワード"
              password={newPassword}
              errors={errors.password}
              setError={updateError}
              setPassword={setNewPassword}
              modal={false}
            />
          </div>

          <div className="mt-5 mb-5">
            <PasswordForm
              identifier="verifyPassword"
              label="変更後のパスワード（再入力）"
              password={confirmPassword}
              errors={errors.verifyPassword}
              setError={updateError}
              setPassword={setConfirmPassword}
              modal={false}
            />
          </div>

          {errors.noMatch && (
            <p className="text-red-500">
              {UserFieldErrorMessages.passwordNoMatchError}
            </p>
          )}

          <div className="flex gap-2">
            <div className="pt-10 text-zinc-400 font-bold">
              パスワードポリシー:
            </div>
            <div className="pt-10 text-zinc-400 font-bold">
              {passwordPolicy}
            </div>
          </div>

          <ConfirmCancelButtons
            confirmText="変更する"
            onConfirm={onConfirm}
            onCancel={onCancel}
          />
        </div>
      ) : (
        <p className="not-found-text">{error_message.user.not_found}</p>
      )}
    </div>
  );
};

export default PasswordResetPage;
