import React from "react";
import { TitleWithDivider } from "../../common/TitleWithDivider";
import CreateNotificationCard from "../../components/Cards/Notification/NewNotificationCard";
import pageHeaders from "../../constants/PageHeaders";

const CreateNotificationPage: React.FC = () => {
  return (
    <>
      <div>
        <TitleWithDivider
          titleText={pageHeaders.NOTIFICATION.add}
          useDivider={true}
        />
        <CreateNotificationCard />
      </div>
    </>
  );
};

export default CreateNotificationPage;
