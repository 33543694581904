import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./UserSlice";
import authReducer from "./AuthSlice";
import apiReducer from "./ApiSlice";
import companyReducer from "./CompanySlice";
import appReducer from "./AppSlice";
import systemReducer from "./SystemSlice";
import userFormReducer from "./UserFormSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    auth: authReducer,
    api: apiReducer,
    company: companyReducer,
    apps: appReducer,
    sys: systemReducer,
    userForm: userFormReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
