import React from "react";

interface ConfirmCancelButtonsProps {
  confirmText: string;
  onConfirm?: () => void;
  cancelText?: string;
  onCancel: () => void;
  form?: boolean;
  disabled?: boolean;
}

export const ConfirmCancelButtons: React.FC<ConfirmCancelButtonsProps> = ({
  confirmText,
  onConfirm,
  cancelText,
  onCancel,
  form = false,
  disabled = false,
}) => {
  return (
    <div className="flex justify-end items-center gap-2.5 mt-10">
      <div className="flex">
        <button
          className="button-cancel"
          onClick={onCancel}
          disabled={disabled}
          type="button"
        >
          {cancelText === undefined ? "キャンセル" : cancelText}
        </button>
        {!form ? (
          <button
            className="button-confirm"
            onClick={onConfirm}
            disabled={disabled}
          >
            {confirmText}
          </button>
        ) : (
          <button className="button-confirm" type="submit" disabled={disabled}>
            {confirmText}
          </button>
        )}
      </div>
    </div>
  );
};
