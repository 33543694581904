import React from "react";
import LoadingIcon from "../../common/LoadingIcon";

interface LoadingModalProps {
  isOpen: boolean;
}

const LoadingModal: React.FC<LoadingModalProps> = ({ isOpen }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center backdrop-brightness-50 z-[1000]">
      <div className="bg-white border border-text-field-border rounded-lg w-50">
        <div className="mayo-card-body p-4 flex justify-between items-center"></div>
        <LoadingIcon></LoadingIcon>
        <div className="flex justify-end gap-2.5 mb-5 mr-4"></div>
      </div>
    </div>
  );
};

export default LoadingModal;
