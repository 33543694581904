import { XCircleIcon } from "@heroicons/react/16/solid";
import React, { useRef } from "react";
import useClickOutside from "./ClickOutsideHandler";
import useDrag from "./DragHandler";

interface WarningModalProps {
  title: string;
  body: string;
  confirmText: string;
  cancelText?: string;
  confirmButtonColor?: string;
  isOpen: boolean;
  onConfirm?: () => void;
  onRequestClose: () => void;
}

const WarningModal: React.FC<WarningModalProps> = ({
  title,
  body,
  confirmText,
  cancelText = "キャンセル",
  isOpen,
  confirmButtonColor = "bg-red-500",
  onConfirm,
  onRequestClose,
}) => {
  const modalRef: React.RefObject<HTMLDivElement> =
    useRef<HTMLDivElement>(null);

  const { position, handleMouseDown } = useDrag(modalRef, isOpen);
  useClickOutside(isOpen, onRequestClose, modalRef);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center backdrop-brightness-50 z-[1000] z-[1000]">
      <div
        ref={modalRef}
        onMouseDown={handleMouseDown}
        style={{
          transform: `translate(${position.x}px, ${position.y}px)`,
        }}
        className="bg-white border border-text-field-border rounded-lg w-[90vw] sm:w-[80vw] md:w-[60vw] lg:w-[40vw] xl:w-[40vw] max-w-[500px] max-h-[90vh] overflow-y-auto"
      >
        <div className="mayo-card-header p-4 flex justify-between items-center">
          <h2 className="text-lg font-semibold">{title}</h2>
          <button onClick={onRequestClose} className="text-xl hover:opacity-50">
            <svg
              className="ml-2 fill-body hover:fill-primary"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <XCircleIcon />
            </svg>
          </button>
        </div>

        <p className="text-black text-lg mb-4 ml-2 mt-4 whitespace-pre-wrap break-words">
          {body}
        </p>
        <div className="flex justify-end gap-2.5 mb-5 mr-4">
          <button
            onClick={onRequestClose}
            className="button-small-white border-text-field-border"
          >
            {cancelText === "" ? "キャンセル" : cancelText}
          </button>
          {confirmText !== "" && (
            <button
              onClick={onConfirm}
              className={`${confirmButtonColor} hover:opacity-50 button-modal`}
            >
              {confirmText}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default WarningModal;
