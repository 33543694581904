import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { TitleWithDivider } from "../../common/TitleWithDivider";
import { SearchBar } from "../../components/SearchBar/SearchBarTemplate";
import { HeaderItems } from "../../common/HeaderItems";
import { getTenantsData } from "../../services/API/Requests";
import LoadingIcon from "../../common/LoadingIcon";
import FilterModal from "../../components/Modals/FilterSelectModal";
import PaginationControls from "../../services/PaginationControls";
import TenantsTables from "../../components/Tables/TenantTable";
import { tenantHeaderMapping } from "../../services/TranslateCsvHeaders";
import handleDownloadCSV from "../../services/CsvDownloadHandler";
import pageHeaders from "../../constants/PageHeaders";

const TenantAdminPage: React.FC = () => {
  const [selectedTenants, setSelectedTenants] = useState<number[]>([]);

  const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);

  const [searchTerm, setSearchTerm] = useState("");

  const [tenantData, setTenantData] = useState<[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [isFiltered, setIsFiltered] = useState<boolean>(false);
  const [queryString, setQueryString] = useState<string>("");

  const [allSelected, setAllSelected] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalTenants, setTotalTenants] = useState<number>(0);

  const [itemsPerPage, setItemsPerPage] = useState<number>(5);

  const totalPages: number = Math.ceil(totalTenants / itemsPerPage);

  const filename = "名分の企業情報_";

  const nonSelectedToast = () => toast.error("企業が選択されていません。");

  const [filters, setFilters] = useState({
    name: "",
    name_kana: "",
    code: "",
    app_name: "",
  });

  useEffect(() => {
    setCurrentPage(1);
  }, [itemsPerPage]);

  const matchingTenants = (selectedIds: number[]) => {
    return tenantData.filter((tenant) => selectedIds.includes(tenant.id));
  };

  const handleTenantSelect = (tenantId: number, force = false) => {
    setSelectedTenants((prevSelected) => {
      if (force) {
        return [...prevSelected, tenantId];
      } else if (prevSelected.includes(tenantId)) {
        setAllSelected(false);
        return prevSelected.filter((id) => id !== tenantId);
      } else {
        return [...prevSelected, tenantId];
      }
    });
  };

  const handleDeselectAll = () => {
    setAllSelected(false);
    setSelectedTenants([]);
  };

  const handleDownloadClick = () => {
    const type = "tenant";
    handleDownloadCSV(
      matchingTenants(selectedTenants),
      filename,
      tenantHeaderMapping,
      type,
    );
  };

  const fetchTenantInformation = async ({
    curPage = currentPage,
    query = queryString,
  } = {}) => {
    getTenantsData(
      setTenantData,
      setTotalTenants,
      setError,
      setLoading,
      curPage,
      itemsPerPage,
      query,
    );
  };
  useEffect(() => {
    if (!isFiltered && searchTerm == "") {
      fetchTenantInformation();
      setCurrentPage(1);
    }
  }, [isFiltered, searchTerm]);

  const onSearch = () => {
    if (searchTerm) {
      const query: string = `&search=${searchTerm}`;
      setQueryString(query);
      fetchTenantInformation({ query: query });
      setIsFiltered(true);
      setCurrentPage(1);
    } else {
      setQueryString("");
      fetchTenantInformation();
      setIsFiltered(false);
    }
  };

  const onFilteredSearch = () => {
    setFilterModalOpen(true);
  };

  const onFilteredModalClose = () => {
    setFilterModalOpen(false);
  };

  const doFilteredSearch = async (filters) => {
    const query: URLSearchParams = new URLSearchParams();

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        let ransackKey;

        switch (key) {
          case "app_name":
            ransackKey = `app_name`;
            break;
          default:
            ransackKey = `q[${key}_cont]`;
            break;
        }
        query.append(ransackKey, filters[key]);
      }
    });

    setQueryString(query.toString());

    if (query.toString()) {
      fetchTenantInformation();
      setIsFiltered(true);
      setCurrentPage(1);
    } else {
      fetchTenantInformation();
    }
  };

  const handlePageChange = (newPage: number) => {
    fetchTenantInformation({ curPage: newPage });
    setCurrentPage(newPage);
  };

  useEffect(() => {
    const updateUserData = async () => {
      fetchTenantInformation({ curPage: 1 });
      setCurrentPage(1);
    };

    updateUserData();
  }, [itemsPerPage]);

  const headerItems: {
    icon: {
      width: number;
      height: number;
      viewBox: string;
      path: string;
    };
    link: string;
    text: string;
    onClick: () => void;
  }[] = [
    {
      icon: {
        width: 22,
        height: 22,
        viewBox: "0 0 22 22",
        path: "M6.5 5H9.25M6.5 9H9.25M6.5 13H9.25M12.75 5H15.5M12.75 9H15.5M12.75 13H15.5M19 19V4.2C19 3.0799 19 2.51984 18.782 2.09202C18.5903 1.71569 18.2843 1.40973 17.908 1.21799C17.4802 1 16.9201 1 15.8 1H6.2C5.07989 1 4.51984 1 4.09202 1.21799C3.71569 1.40973 3.40973 1.71569 3.21799 2.09202C3 2.51984 3 3.0799 3 4.2V19M21 19H1M15 16H21M18 19V13",
      },
      link: "/company/create",
      text: "企業新規追加",
      onClick: () => console.log("tenant create clicked"),
    },
    {
      icon: {
        width: 24,
        height: 24,
        viewBox: "0 0 24 24",
        path: "M17 3.33782C19.989 5.06687 22 8.29859 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 8.29859 4.01099 5.06687 7 3.33782M8 12L12 16M12 16L16 12M12 16V2",
      },
      link: "",
      text: "CSVダウンロード",
      onClick:
        selectedTenants.length === 0 ? nonSelectedToast : handleDownloadClick,
    },
    // {
    //   icon: {
    //     width: 22,
    //     height: 22,
    //     viewBox: "0 0 22 22",
    //     path: "M6 19.6621C3.01099 17.9331 1 14.7013 1 10.9999C1 5.47709 5.47715 0.999939 11 0.999939C16.5228 0.999939 21 5.47709 21 10.9999C21 14.7014 18.989 17.9331 16 19.6621M15 11L11 7.00001M11 7.00001L7 11M11 7.00001V21",
    //   },
    //   link: "/company/manage/csvUpload",
    //   text: "CSVアップロード",
    //   onClick: () => console.log("CSV Upload clicked"),
    // },
  ];

  return (
    <>
      <div>
        <TitleWithDivider
          titleText={pageHeaders.TENANT.main}
          useDivider={true}
        />
        <SearchBar
          onSearch={onSearch}
          onFilterSearch={onFilteredSearch}
          searchBarText={"テナント検索キーワード"}
          buttonLeft={"検索"}
          buttonRight={"絞込検索"}
          setSearchTerm={setSearchTerm}
        ></SearchBar>
        <div className="flex justify-between">
          <HeaderItems
            items={headerItems}
            topPadding={5}
            useDivider={false}
          ></HeaderItems>
          <div className="mt-5 flex flex-center justify-end items-center">{`総テナント数: ${totalTenants}企業`}</div>
        </div>
        <div className="mb-5"></div>
        {loading ? (
          <LoadingIcon />
        ) : (
          <>
            <TenantsTables
              onTenantSelect={handleTenantSelect}
              onDeselectAll={handleDeselectAll}
              tenantInformation={tenantData}
              allSelected={allSelected}
              loading={loading}
              setAllSelected={setAllSelected}
            />
            <PaginationControls
              currentPage={currentPage}
              totalPages={totalPages}
              totalItems={totalTenants}
              displayedItem={"企業"}
              onPageChange={handlePageChange}
              setPerPage={setItemsPerPage}
              perPage={itemsPerPage}
            />
          </>
        )}
        {/* <WarningModal
          title={warningModalTitle}
          body={warningModalBody}
          confirmText={warningModalConfirm}
          isOpen={deleteModalOpen}
          onConfirm={delete}
          onRequestClose={closeDeleteModal}
        ></WarningModal> */}
        <FilterModal
          type={"tenant"}
          isOpen={filterModalOpen}
          onRequestClose={onFilteredModalClose}
          onApplyFilters={doFilteredSearch}
          filters={filters}
        ></FilterModal>
      </div>
    </>
  );
};

export default TenantAdminPage;
