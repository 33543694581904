import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { error_message } from "../../constants/Errors";

const ContractRoute = ({ element: Component, appName, ...rest }) => {
  const [hasLicense, setHasLicense] = useState(false);
  const availableApps = useSelector((state) => state.apps.availableApps);

  useEffect(() => {
    if (availableApps && Array.isArray(availableApps)) {
      if (availableApps.includes(appName)) {
        setHasLicense(true);
      } else {
        setHasLicense(false);
      }
    }
  }, [availableApps, appName]);

  if (!hasLicense) {
    return (
      <div className="flex flex-col">
        <div className="min-h-screen flex flex-col items-center justify-center">
          <h1 className="text-4xl font-bold">
            {error_message.generic.access_denied}
          </h1>
          <p className="text-lg mt-4">
            {error_message.generic.app_not_available(appName)}
          </p>
        </div>
      </div>
    );
  }

  return <Component {...rest} />;
};

export default ContractRoute;
