import React, { useState } from "react";

import DeleteModal from "../Modals/WarningModal";
import EditModal from "../Modals/WarningModal";
import stampData from "../../templates/StampData";
import PaginationControls from "../../services/PaginationControls";
import { STAMP } from "../../types/stamps";
import { error_message } from "../../constants/Errors";

const StampSettings = ({}) => {
  const deleteModalTitle: string = "削除確認";
  const deleteModalBody: string = "スタンプを削除します。よろしいですか？";
  const deleteModalConfirm: string = "削除";

  const editModalTitle: string = "変更確認";
  const editModalBody: string = "スタンプを変更します。よろしいですか？";
  const editModalConfirm: string = "変更";

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

  const [selectedUsers, setSelectedUsers] = useState([]); // init 0 選択されている
  const [currentPage, setCurrentPage] = useState<number>(1); // ページの設定
  const [selectAll, setSelectAll] = useState<boolean>(false); // 管理するselect-allの状態

  const itemsPerPage: number = 5;
  const lastStampIndex: number = currentPage * itemsPerPage;
  const firstStampIndex: number = lastStampIndex - itemsPerPage;
  const currentStamps: STAMP[] = stampData.slice(
    firstStampIndex,
    lastStampIndex,
  );

  const totalPages: number = Math.ceil(stampData.length / itemsPerPage);

  const handleOpenDeleteModal = (index) => {
    // setSelectedCardIndex(index);
    setDeleteModalOpen(true);
  };

  const handleCancelDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleConfirmDeleteModal = () => {
    setDeleteModalOpen(false);
    //handleDelete(selectedCardIndex);
  };

  const handleOpenEditModal = (index) => {
    // setSelectedCardIndex(index);
    setEditModalOpen(true);
  };

  const handleCancelEditModal = () => {
    setEditModalOpen(false);
  };

  const handleConfirmEditModal = () => {
    setEditModalOpen(false);
    //handleDelete(selectedCardIndex);
  };

  return (
    <div>
      <div className="border border-text-field-border rounded-xl border-r-1">
        <div className="overflow-x-auto rounded-xl">
          <table className="min-w-full table-fixed mayo-card-header">
            <thead className="border-b border-text-field-border">
              <tr>
                <th className="w-8 p-5"></th>
                <th scope="col" className="w-10 text-left label-light-blue-xl">
                  表示順
                </th>
                <th
                  scope="col"
                  className="w-40 text-left text-xl label-light-blue-xl"
                >
                  スタンプ
                </th>
                <th
                  scope="col"
                  className="w-10 text-left text-xl label-light-blue-xl"
                >
                  更新
                </th>
                <th
                  scope="col"
                  className="w-10 text-left text-xl label-light-blue-xl"
                >
                  削除
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-mayo-light-gray">
              {currentStamps.map((stamp, index) => (
                <tr key={stamp.id}>
                  <td className="text-center w-8">
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="inline-block hover:opacity-50"
                    >
                      <path
                        d="M18.4467 7.47766C18.1787 7.20962 17.7457 7.20962 17.4777 7.47766C17.2096 7.7457 17.2096 8.17869 17.4777 8.44673L19.3402 10.3024H11.6838V2.64605L13.5326 4.50172C13.8007 4.76976 14.2337 4.76976 14.5017 4.50172C14.7698 4.23368 14.7698 3.80069 14.5017 3.53265L10.9966 0L7.48454 3.53952C7.35395 3.6701 7.28522 3.84192 7.28522 4.02749C7.28522 4.4055 7.5945 4.71478 7.97251 4.71478C8.15807 4.71478 8.3299 4.63917 8.46048 4.50859L10.3093 2.65292V10.3093H2.64605L4.50172 8.46048C4.76976 8.19244 4.76976 7.75945 4.50172 7.49141C4.23368 7.22337 3.80069 7.22337 3.53265 7.49141L0 10.9966L3.53265 14.5086C3.66323 14.6392 3.83505 14.7079 4.02062 14.7079C4.39863 14.7079 4.7079 14.3986 4.7079 14.0206C4.7079 13.8351 4.6323 13.6632 4.50172 13.5326L2.65292 11.6838H10.3093V19.3402L8.46048 17.4845C8.19244 17.2165 7.75945 17.2165 7.49141 17.4845C7.36082 17.6151 7.2921 17.7869 7.28522 17.9725C7.28522 18.1581 7.35395 18.3299 7.48454 18.4605L10.9966 22L14.5086 18.4674C14.7766 18.1993 14.7766 17.7663 14.5086 17.4983C14.2405 17.2302 13.8076 17.2302 13.5395 17.4983L11.6838 19.354V11.6976H19.3402L17.4845 13.5464C17.2165 13.8144 17.2165 14.2474 17.4845 14.5155C17.6151 14.646 17.7869 14.7148 17.9725 14.7216C18.1581 14.7216 18.3299 14.6529 18.4605 14.5223L22 11.0103L18.4605 7.49828L18.4467 7.47766Z"
                        fill="#B9B9B9"
                      />
                    </svg>
                  </td>
                  <td className="w-10 text-left">{stamp.id}</td>
                  <td>
                    {stamp.uri ? (
                      <img
                        src={stamp.uri}
                        alt={stamp.uri}
                        className="w-40 h-auto py-5"
                      />
                    ) : (
                      <div className="p-10 w-20 h-auto rounded-md text-bold text-xl text-center bg-slate-100">
                        {error_message.workjoy.no_stamp_data}
                      </div>
                    )}
                  </td>

                  <td className="w-10">
                    <button onClick={() => handleOpenEditModal(index)}>
                      <svg
                        width="23"
                        height="23"
                        viewBox="0 0 23 23"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="hover:opacity-50"
                      >
                        <path
                          d="M10 3.99998H5.8C4.11984 3.99998 3.27976 3.99998 2.63803 4.32696C2.07354 4.61458 1.6146 5.07353 1.32698 5.63801C1 6.27975 1 7.11983 1 8.79998V17.2C1 18.8801 1 19.7202 1.32698 20.362C1.6146 20.9264 2.07354 21.3854 2.63803 21.673C3.27976 22 4.11984 22 5.8 22H14.2C15.8802 22 16.7202 22 17.362 21.673C17.9265 21.3854 18.3854 20.9264 18.673 20.362C19 19.7202 19 18.8801 19 17.2V13M6.99997 16H8.67452C9.1637 16 9.40829 16 9.63846 15.9447C9.84254 15.8957 10.0376 15.8149 10.2166 15.7053C10.4184 15.5816 10.5914 15.4086 10.9373 15.0627L20.5 5.49998C21.3284 4.67156 21.3284 3.32841 20.5 2.49998C19.6716 1.67156 18.3284 1.67155 17.5 2.49998L7.93723 12.0627C7.59133 12.4086 7.41838 12.5816 7.29469 12.7834C7.18504 12.9624 7.10423 13.1574 7.05523 13.3615C6.99997 13.5917 6.99997 13.8363 6.99997 14.3255V16Z"
                          stroke="#0695FF"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </td>
                  <td>
                    <button onClick={() => handleOpenDeleteModal(index)}>
                      <svg
                        width="20"
                        height="22"
                        viewBox="0 0 20 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="hover:opacity-50"
                      >
                        <path
                          d="M14 5V4.2C14 3.0799 14 2.51984 13.782 2.09202C13.5903 1.71569 13.2843 1.40973 12.908 1.21799C12.4802 1 11.9201 1 10.8 1H9.2C8.07989 1 7.51984 1 7.09202 1.21799C6.71569 1.40973 6.40973 1.71569 6.21799 2.09202C6 2.51984 6 3.0799 6 4.2V5M8 10.5V15.5M12 10.5V15.5M1 5H19M17 5V16.2C17 17.8802 17 18.7202 16.673 19.362C16.3854 19.9265 15.9265 20.3854 15.362 20.673C14.7202 21 13.8802 21 12.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V5"
                          stroke="#0695FF"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <PaginationControls
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      ></PaginationControls>
      {/* <div className="flex justify-center mt-4 space-x-2">
        {getPageNumbers().map((item, index) => (
          <button
            key={index}
            onClick={() => {
              if (item.type === "page") {
                setCurrentPage(item.value);
              } else if (item.type === "dots") {
                if (item.direction === "backward") {
                  handlePreviousPages();
                } else if (item.direction === "forward") {
                  handleNextPages();
                }
              }
            }}
            className={`w-10 border ${
              currentPage === item.value
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-black"
            } rounded-full`}
          >
            {item.type === "page" ? item.value : "..."}
          </button>
        ))}
      </div> */}
      <DeleteModal
        isOpen={deleteModalOpen}
        onRequestClose={handleCancelDeleteModal}
        onConfirm={handleConfirmDeleteModal}
        title={deleteModalTitle}
        body={deleteModalBody}
        confirmText={deleteModalConfirm}
        cancelText={""}
      ></DeleteModal>
      <EditModal
        isOpen={editModalOpen}
        onRequestClose={handleCancelEditModal}
        onConfirm={handleConfirmEditModal}
        title={editModalTitle}
        body={editModalBody}
        confirmText={editModalConfirm}
        confirmButtonColor="bg-button-light-blue"
        cancelText={""}
      ></EditModal>
    </div>
  );
};

export default StampSettings;
