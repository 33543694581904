import React from "react";
import { TitleWithDivider } from "../../common/TitleWithDivider.tsx";
import { useParams } from "react-router-dom";
import ViewTenantForm from "../../components/Forms/ViewTenantForm.tsx";
import pageHeaders from "../../constants/PageHeaders.tsx";

const ViewTenantPage: React.FC = () => {
  const { tenantCode } = useParams<{ tenantCode: string }>();

  return (
    <>
      <div>
        <TitleWithDivider
          titleText={pageHeaders.TENANT.edit}
          useDivider={true}
        />
        <ViewTenantForm tenantCode={tenantCode}></ViewTenantForm>
      </div>
    </>
  );
};

export default ViewTenantPage;
