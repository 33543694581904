import { translateAndReorderHeaders } from "./TranslateCsvHeaders";
import { sortApps } from "./Utilities";

export const convertToCSV = (data, headerMapping) => {
  if (!data || data.length === 0) return "";

  const orderedHeaders = translateAndReorderHeaders(headerMapping);

  const headers = orderedHeaders.join(",");

  const rows = data.map((row) =>
    orderedHeaders
      .map((key) => {
        const originalKey = Object.keys(headerMapping).find(
          (k) => headerMapping[k] === key,
        );
        let value;

        if (key === "ログインパスワード") {
          value = ""; // change this to custom hidden value if required, eg *****
        } else if (key === "利用アプリ" && row.user_apps) {
          value = sortApps(row.user_apps)
            .map((app) => app.app_name)
            .join(",");
        } else {
          value = row[originalKey] || "";
        }

        return `"${value}"`;
      })
      .join(","),
  );

  return [headers, ...rows].join("\n");
};

export const convertTenantToCsv = (data, headerMapping): string => {
  if (!data || data.length === 0) return "";

  const excludedFields = [
    "microsoft_365_auth",
    "google_auth",
    "contract_count",
    "name_english",
    "disable_call_recording",
  ];

  const contractFields = [
    "app_id",
    "app_name",
    "status",
    "contract_count",
    "current_users",
    "start_date",
    "end_date",
  ];

  const orderedHeaders = translateAndReorderHeaders(headerMapping);
  const headers = orderedHeaders.join(",");

  const rows = data.map((tenant) => {
    const tenantInfo = Object.entries(tenant)
      .filter(([key]) => !excludedFields.includes(key) && key !== "contracts")
      .map(([_, value]) => `"${value}"`)
      .join(",");

    if (tenant.contracts && tenant.contracts.length > 0) {
      return tenant.contracts
        .map((contract) => {
          const contractInfo = contractFields
            .map((field) => {
              const key =
                field === "contract_contract_count" ? "contract_count" : field;

              if (key === "status") {
                if (contract[key] === "active") return `"契約中"`;
                if (contract[key] === "inactive") return `"未契約"`;
                if (contract[key] === "no_contract") return `"未契約"`;
                if (contract[key] === "expired") return `"契約終了"`;
                return `"${contract[key] || ""}"`;
              }

              if (key === "current_users" || key === "contract_count") {
                if (contract[key] === 0) return "0";
              }
              return `"${contract[key] || ""}"`;
            })
            .join(",");
          return `${tenantInfo},${contractInfo}`;
        })
        .join("\n");
    } else {
      return `${tenantInfo},` + `"未契約"`;
    }
  });

  return [headers, ...rows].join("\n");
};
