import React from "react";
import { useSelector } from "react-redux";
import { error_message } from "../../constants/Errors";

const RegularRoute = ({ element: Component, ...rest }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAdmin);

  if (isAuthenticated) {
    return (
      <div className="flex flex-col">
        <div className="min-h-screen flex flex-col items-center justify-center">
          <h1 className="text-4xl font-bold">
            {error_message.generic.access_denied}
          </h1>
          <p className="text-lg mt-4">{error_message.generic.no_access}</p>
        </div>
      </div>
    );
  }

  return <Component {...rest} />;
};

export default RegularRoute;
