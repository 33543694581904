import { ShieldExclamationIcon } from "@heroicons/react/16/solid";
import { useKeycloak } from "@react-keycloak/web";
import React from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { error_message } from "../../constants/Errors";

const NoAuthModal: React.FC = () => {
  const { keycloak } = useKeycloak();
  const navigate: NavigateFunction = useNavigate();

  const sessionExpired = keycloak.authenticated;

  const returnToAuthPage = () => {
    keycloak.logout();
    navigate("/");
  };
  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center backdrop-brightness-50 z-[1000]">
      <div className="bg-white border border-text-field-border rounded-lg w-100 p-6">
        <div className="flex flex-col items-center justify-center">
          <ShieldExclamationIcon className="w-16 h-16 mr-2 mb-4"></ShieldExclamationIcon>
          {!sessionExpired ? (
            <p className="mb-4 text-center">
              {error_message.generic.session_expired}
            </p>
          ) : (
            <p className="mb-4 text-center">ログインしてください</p>
          )}
          <button className="button-default" onClick={returnToAuthPage}>
            {!sessionExpired ? "再ログイン" : "ログイン"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NoAuthModal;
